import React from "react";
import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import NavBar from "../../components/NavBar";
import Section10 from "../../components/sections/Section10";
import Section5 from "../../components/sections/Section5";
import Section8 from "../../components/sections/Section8";
import NavBarLogo from "../../assets/landingpages/lenco-logo_shtma7.svg";
import HeroImage from "../../assets/landingpages/invoice/image_144_uhxd1b.svg";
import Section10Image from "../../assets/landingpages/invoice/image_161_p4vrcw.svg";
import Section10Image1 from "../../assets/landingpages/invoice/image_170_vfufik.svg";
import Section10Image2 from "../../assets/landingpages/invoice/Group_7100_o3o5ep.svg";
import Section10Image3 from "../../assets/landingpages/invoice/BankorCardImage.svg";
import "../../styles/pages/invoice.scss";

function InvoicePage() {
    const HeroTitle = <>At Lenco, billing  <br />customers is easy </>;

    const heroDescription = (
        <>
            Create, manage and issue invoices. <br/> Automate payment reminders and invoice
            reconciliation.<br/> Invoices in a whole new way.
        </>
    );

    const title1 = <>Collections Management <br/> Dashboard</>;

    return (
        <div className="invoice-page">

            <NavBar logo={NavBarLogo}/>
            <Hero
                title={HeroTitle}
                subtitle="Coming soon"
                subtitleClass="subtitle-text"
                description={heroDescription}
                image={HeroImage}
                classImage="text-center animate__animated animate__fadeInRight animate__delay-1s heroImage"
            />
            {/* <div className="invoice-cardwide">
        <CardWide
          title={billingManagement}
          description="It’s never been easier to charge and receive from your customers. In your Lenco account,
        you have everything you need to manage your receipts and invoices intelligently."
        />
      </div> */}

            <Section10
                title2={title1}
                description2="Track all your invoices by due date and payment
        status in one place. View your collections performance."
                image1={Section10Image}
                className1="col-lg-5 col-md-6"
                className2="col-lg-6 ms-auto col-md-6"
                toggle2={true}
                toggle3={true}
            />

            <Section10
                title1="Robust Invoicing"
                description1="Create and customize invoices with your brand information.
Take control of your invoicing and accounting needs with ease.
Lenco let’s you send unlimited invoices for free."
                image2={Section10Image1}
                className1="col-lg-5 me-auto col-md-6"
                className2="col-lg-6 col-md-6"
                sectionten="section-ten-row row align-items-center colummnReverse"
                toggle1={true}
                toggle4={true}
                imageClass="imghoverzoom text-center customImageB"
            />
            <Section10
                title2="Automatic Billing Notifications"
                description2="Schedule automatic billing reminders to be sent to your customers
before, after, or when bills are due. With automated billing, you
prevent your customers from delaying payments and reduce
bad debts."
                image1={Section10Image2}
                className1="col-lg-5 col-md-6"
                className2="col-lg-6 ms-auto col-md-6 "
                toggle2={true}
                toggle3={true}
            />
            <Section10
                title1="Get Paid With Card or Bank Transfer"
                description1="Customers can select how they want to pay you. They can pay online
with a debit card or through bank transfer."
                image2={Section10Image3}
                className1="col-lg-6 me-auto col-md-6"
                className2="col-lg-5 col-md-6"
                sectionten="section-ten-row row align-items-center colummnReverse"
                toggle1={true}
                toggle4={true}
            />
            <Section8/>
            <Section5/>
            <Footer
                logo={NavBarLogo}
                appbuttontitle="Open an account"
                country="Nigeria"
            />
        </div>
    );
}

export default InvoicePage;
