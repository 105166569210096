
import billPayment from "./billpayment.svg"
import businessAutomation from "./businessautomation.svg"
import promotion from "./promotion.svg"
import virtualAccount from "./virtualaccount.svg"
import businessAccount from "./businessaccount.svg"

import controls from "./controls.svg"
import teams from "./team.svg"
import report from "./report.svg"
import expenseManagment from "./expensemanagment.svg"



export default {

    billPayment,
    businessAutomation,
    promotion,
    virtualAccount,
    businessAccount,
    teams,
    report,
    controls,
    expenseManagment
}