import images from "../../assets/landingpages/home"
import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";

const { eziPay, giftedMum, hotelDotNg, healthTracka, drinksDotNg } = brandImages;


const { HeroImage, BusinessAccount, Cards, PayBills, Payouts, ExpenseManagement, MaxSafe, MaxSec, MaxTrust } = images;

const sliderSettings = {

    slidesToShow: 6,
    centerMode: true,
    lazyLoad: false,
    useTransform: true,
    autoplay: true,
    autoplaySpeed: 2000,

    cssEase: 'linear',


    speed: 9000,








    responsive: [
        {
            breakpoint: 1200,
            settings: {
                centerMode: true,
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                centerMode: true,
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 991,
            settings: {

                slidesToShow: 4,

            },
        },

        {
            breakpoint: 760,
            settings: {
                centerMode: true,
                slidesToShow: 2,

            },
        }
    ],
};



let allBrands = [];

for (const prop in brandImages)
{
    allBrands.push({
        image: brandImages[prop]
    })
}


export default {
    link: "/",
    name: "Home",
    sections: [


        {
            title: "Trusted by thousands of fast-growing businesses",
            dataType: "brand",
            className: "home-brand",
            slider: true,
            extraProps: {
                parent: {
                    ...sliderSettings
                }
            },
            classNames:

                { title: "text-center" }
            ,

            data: [
                ...allBrands

            ]

        }
        ,
        {
            dataType: "image",
            className: "home-image",
            classNames:

                { title: "text-center" },
            data: [
                {
                    image: HeroImage
                }

            ]
        },
        {

            title: "Lenco is not a bank. We are better!",
            description: "Built for Startups, Sole Traders, SMEs and Freelancers.",

            dataType: "splitter",
            classNames:

                { title: "text-center home-head", description: "text-center home-description" }

            ,

            data: [
                {
                    title: "Business Current Account",
                    description: "Real bank accounts for fast growing businesses. These accounts accept cheque, bank walk-in deposit, online bank transfer and all forms of deposits.",
                    image: BusinessAccount,
                    actions: [
                        { title: "Learn More", link: "/business-current-account" }


                    ],
                },

                {
                    title: "Physical & Virtual cards",
                    description: "Issue cards for your team to better track spending and manage your business. All our cards come with custom card management and access control.",
                    image: Cards,
                    actions: [
                        { title: "Learn More", link: "/cards" }


                    ],
                },
                {
                    title: "Pay Bills",
                    description: "Create, send and manage invoices, automate payment reminders, automatic invoice reconciliation reports. Attach invoices to payments and much more.",
                    image: PayBills,
                    actions: [
                        { title: "Learn More", link: "/bill- payment" }


                    ],


                },
                {
                    title: "Payouts",
                    description: "Make up to 5,000 payments instantly with no effort. Upload a file to make bulk payouts from the dashboard or integrate API to automate instant payouts.",
                    image: Payouts,
                    actions: [
                        { title: "Learn More", link: "/payouts" }


                    ],
                },
                {
                    title: "Expense Management",
                    description: "Experience the power of automated reconciliation with transaction cartegory, receipt grouping and post transaction comments.",
                    image: ExpenseManagement,
                    actions: [
                        { title: "Learn More", link: "/expense-management" }


                    ],
                }








            ]
        },
        {
            title: "Your smart business account is backed by",

            classNames:

                { title: "text-center" }
            ,

            data: [
                {
                    title: "Maximum Trust",
                    description: "We host your business account in a Central bank of Nigeria licensed bank.",
                    image: MaxTrust
                },
                {
                    title: "Maximum Security",
                    description: "Bank grade security with two factor enabled account authentication on your account for added security.",
                    image: MaxSec
                },
                {
                    title: "Maximum Safety",
                    description: "Your money is issued by NDIC with our licensed bank partner",
                    image: MaxSafe
                },




            ]

        }



    ]

}