import React from "react";
import { Route } from "react-router";
// import Home from "../home";
import WaitlistPage from "../page/waitlist";
import BusinessCurrentAccountPage from "../page/bca";
import CardsPage from "../page/cards";
import InvoicePage from "../page/invoice";
import HeroImageKenya from "../assets/landingpages/waitlist/LencoDashboardKenya.svg";
import HeroImageRwanda from "../assets/landingpages/waitlist/LencoDashboardRwanda.svg";


import KenyaFlag from "../assets/landingpages/KenyaFlag.svg";
import RwandaFlag from "../assets/landingpages/RwandaFlag.svg";
import NigeriaFlag from "../assets/landingpages/NigeriaFlag.svg"

// import FAQPage from "../page/faq";
// import PayoutsPage from "../page/payouts";
// import AboutPage from "../page/about";
import FeesPage from "../page/fees";
import SupportPage from "../page/support";
// import BillPayment from "../page/billpayment";
// import Growth from "../page/growth";
// import ExpenseManagement from "../page/expensemanagement";
import Developer from "../page/developer";
// import Hospitality from "../page/hospitality";


// import Startup from "../page/startup";
import Page from "../components/Page";
import Home from "../components/templates/Home"





function Routes() {
    return (
        <>
            <Route path="/" render={(props) => {
                return <Page {...props} template="home" />
            }} exact />
            <Route
                path="/business-current-account"
                component={Page}
                exact
            />


            <Route path="/cards" render={(props) => {
                return <Page {...props} template="cards" />
            }} exact />
            <Route path="/invoice" component={Page} exact />
            {/* <Route path="/faq" component={FAQPage} exact /> */}
            <Route path="/payouts" component={Page} exact />
            {/* <Route path="/about" component={AboutPage} exact /> */}
            <Route path="/fees" component={FeesPage} exact />
            <Route path="/support" component={SupportPage} exact />
            <Route path="/bill-payment" component={Page} exact />
            {/* <Route path="/growth" component={Page} exact /> */}
            <Route path="/expense-management" component={Page} exact />

            <Route path="/developer" component={Developer} exact />

            <Route path="/retail" render={(props) => {
                return <Page {...props} template="industry" />
            }} exact />
            <Route path="/logistics" render={(props) => {
                return <Page {...props} template="industry" />
            }} exact />
            <Route path="/startup" strict exact render={(props) => {
                return <Page {...props} template="industry" />
            }} />
            <Route path="/hospitality" render={(props) => {
                return <Page {...props} template="industry" />
            }} exact />


            <Route path="/ke" exact>
                <WaitlistPage
                    heroImage={HeroImageKenya}
                    pagetitle="Kenya Waitlist"
                    pagename="Kenya"
                    countryflagImage={KenyaFlag}
                    countryflag="Kenya"
                    countryFlag1={NigeriaFlag}
                    country1="Nigeria"
                    country1link="/"
                    countryFlag2={RwandaFlag}
                    country2="Rwanda"
                    country2link="/rw"
                    pageurl="/ke"
                // country="Kenya"
                />
            </Route>
            <Route path="/rw" exact>
                <WaitlistPage
                    heroImage={HeroImageRwanda}
                    pagetitle="Rwanda Waitlist"
                    pagename="Rwanda"
                    countryflagImage={RwandaFlag}
                    countryflag="Rwanda"
                    country1="Kenya"
                    countryFlag1={KenyaFlag}
                    country1link="/ke"
                    country2="Nigeria"
                    countryFlag2={NigeriaFlag}
                    country2link="/"
                    pageurl="/rw"
                // country="Rwanda"
                />
            </Route>
        </>
    );
}

export default Routes;
