import React from 'react'


import HeroMain from "../HeroMain";


import Section5 from "../sections/Section5";
import Section6 from "../sections/Section6";
import Section7 from "../sections/Section7";

import "../../styles/pages/home.scss";

import HeroImage from "../../assets/landingpages/home/heroimage.svg"
import Blur from "../../assets/landingpages/home/blur.svg"
import { Link } from "react-router-dom";

import PageTemplate from './PageTemplate';
import TransferBanner from '../sections/TransferBanner';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeromMainDescriptionTwoRender = (
    <>
        <div className="HeroMainDescription">
            <Link to="/business-current-account">Bank accounts</Link>
            <span> | </span> <Link to="/payouts">Payments</Link> <span> | </span>
            <Link to="/expense-management">Expense management</Link>
        </div>
    </>
);

function Home({ children, hiddenSections = [], ...others }) {


    return (

        <PageTemplate nameSpace='home' {...others}>
            <div style={{
                backgroundImage: `url(${Blur})`,
                top: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                zIndex: -1,

            }}></div>
            <HeroMain
                // eslint-disable-next-line no-template-curly-in-string
                description="Banking that finally works"
                descriptiontwo={HeromMainDescriptionTwoRender}


                className="container-fluid hero-main hero-main-light"
            // country="Nigeria"
            />
            {children}

            <TransferBanner />
            <Section5 />
            <Section6 />
            <Section7 />

        </PageTemplate>

    );
}

export default Home;
