const whatsappNumber = process.env.REACT_APP_CONTACT_NUMBER_WHATSAPP;
const whatsappLink = "https://wa.me/" + whatsappNumber.replace(/[\s+]+/g, "");

const phoneNumber = process.env.REACT_APP_CONTACT_NUMBER;
const phoneLink = "tel:" + whatsappNumber.replace(/[\s]+/g, "");

const emailAddress = process.env.REACT_APP_EMAIL_ADDRESS;
const emailLink = "mailto:" + emailAddress;
const devEmailAddress = process.env.REACT_APP_DEV_EMAIL_ADDRESS;
const devEmailLink = "mailto:" + devEmailAddress;

export const Contacts = {
    phone: {
        text: phoneNumber,
        link: phoneLink,
    },
    whatsapp: {
        text: whatsappNumber,
        link: whatsappLink,
    },
    email: {
        text: emailAddress,
        link: emailLink,
    },
    devEmail: {
        text: devEmailAddress,
        link: devEmailLink,
    }
};

const homeUrl = process.env.REACT_APP_BASE_URL || "https://lenco.co";

export const Urls = {
    home: homeUrl,
    fees: homeUrl + "/fees",
    privacy: homeUrl + "/privacy",
    terms: homeUrl + "/terms",
    faq: homeUrl + "/faq",
    blog: "https://blog.lenco.ng",
    signup: homeUrl + "/signup",
    login: homeUrl + "/login",
    careers: "https://careers.lenco.co",
    devUrl: "http://lenco-api.readme.io/",
    apiUrlAccounts: "https://lenco-api.readme.io/reference/get-accounts",
    apiUrlRecipients: "https://lenco-api.readme.io/reference/get-recipients",
    apiUrlBanks: "https://lenco-api.readme.io/reference/get-banks",
    apiUrlResolve: "https://lenco-api.readme.io/reference/resolve-account",
    apiUrlTransactions: "https://lenco-api.readme.io/reference/get-transactions",

    apiUrlVirtualAccount: "https://lenco-api.readme.io/reference/create-virtual-accounts",
    apiUrlBillPayment: "https://lenco-api.readme.io/reference/bill-payments",
    apiUrlWebhook: "https://lenco-api.readme.io/reference/webhooks",
    apiUrlPricing: "https://lenco-api.readme.io/reference/pricing",
    appleStore: process.env.REACT_APP_APPLE_STORE,
    playStore: process.env.REACT_APP_PLAY_STORE
}
