import images from "../../assets/landingpages/invoice"

import { Urls } from "../../helper";


const { HeroImage, Collection, Robust, Reminder, Transfer } = images;


export default {


    name: "Invoice",
    title: "At Lenco, billing customers is easy",
    description: "Create, manage and issue invoices. Automate payment reminders and invoice reconciliation. Invoices in a whole new way.",
    link: "/invoice",
    image: HeroImage,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [

        {
            dataType: "splitter",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {
                    title: "Collections Management Dashboard",
                    description: "Track all your invoices by due date and payment status in one place. View your collections performance.",
                    image: Collection
                },
                {
                    title: "Robust Invoicing",
                    description: "Create and customize invoices with your brand information. Take control of your invoicing and accounting needs with ease. Lenco let’s you send unlimited invoices for free.",
                    image: Robust
                },
                {
                    title: "Automatic Billing Notifications",
                    description: "Schedule automatic billing reminders to be sent to your customers before, after, or when bills are due. With automated billing, you prevent your customers from delaying payments and reduce bad debts.",
                    image: Reminder
                },
                {
                    title: "Get Paid With Bank Transfer",
                    description: "Customers can select how they want to pay you. They can pay online through bank transfer.",

                    image: Transfer
                },





            ]

        }


    ]

}