import React from "react";
import CardxlInner from "./CardxlInner";
import "../styles/cardxl.scss";

function Cardxl() {
  return (
    <div className="cardxl">
      <CardxlInner
        title="Separate personal expenses from business."
        description="Don’t use personal card for business expense."
      />
      <CardxlInner
        title="Track and Manage Expenses Intelligently."
        description="Set daily card limits. Get real-time spend notifications and your
expense categorizes."
      />
      <CardxlInner
        title="Multiple Cards for Your Team."
        description="Issue unlimited cards to your team members, set spend limits 
and match receipts."
      />
      <CardxlInner
        title="Real-time Receipt Tracking."
        description="Every time you make a purchase, we notify you to upload the 
payment receipt."
      />
     
    </div>
  );
}

export default Cardxl;
