import React from "react";
import "../styles/cardmd.scss";

function SmartCard({ nameSpace = "smart-", image, title, description, pClassName, imageType = "icon", shadow = true, center = false }) {
    return (
        <div className={`cardmd ${nameSpace}card ${shadow ? `${nameSpace}shadow ` : ""} ${center ? `${nameSpace}center` : ""} ${nameSpace}card-type-${imageType}`} >
            <div className={`cardmd-container ${nameSpace}card-container`}>
                <div className={`${nameSpace}card-${imageType}-container`}>
                    <img src={image} alt="" className={`${nameSpace}card-${imageType}`} />
                </div>
                <div>
                    {/* <ScrollAnimation animateIn="animate__fadeInDown"> */}
                    <h5 className={`mobile-header2 ${nameSpace}card-title`}>{title}</h5>
                    {/* </ScrollAnimation> */}
                    <p className={pClassName + ` ${nameSpace}card-subtitle`}>{description}</p>
                </div>
            </div>
        </div>
    );
}

export default SmartCard;
