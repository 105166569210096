
import FastAccount from "./FastAccount.svg"
import LinkedAccount from "./LinkedAccount.svg"
import TeamBanking from "./TeamBank.svg"
import MultipleBusinesses from "./MultipleBusinesses.svg"
import HeroImage from "./HeroImage.svg"
import RobustBusiness from "./RobustBusiness.svg"
import Convenient from "./Convenient.svg"
import CustomNotification from "./CustomNotification.svg"




export default {
    FastAccount,
    LinkedAccount,
    TeamBanking,
    MultipleBusinesses,
    HeroImage,
    RobustBusiness,
    Convenient,
    CustomNotification


}