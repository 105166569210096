import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import NavBarLogo from "../../assets/landingpages/lenco-logo_shtma7.svg";
import AppButton from '../../components/Button';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';
import NavBar from "../../components/NavBar";
import Section7 from '../../components/sections/Section7';
import "../../styles/pages/support.scss"


import {Field, Form, Formik} from "formik";
import axios from 'axios';

import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import contactIconNumber from "../../assets/landingpages/Vector_1_slalwt.svg";
import contactIconWhatsapp from "../../assets/landingpages/Vector_2_xjwfx2.svg";
import contactIconEmail from "../../assets/landingpages/Vector_3_n0vfoc.svg";
import {Contacts} from "../../helper";

function SupportPage() {
    const [serverState, setServerState] = useState({ok: false, msg: ""});

    const handleServerResponse = (ok, msg) => {
        setServerState({ok, msg});
    };
    const handleOnSubmit = (values, actions) => {
        axios({
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + "/api/support",
            data: values,
            headers: {"Content-Type": "application/json"},
        })
            .then((response) => {
                if (response.data.success) {
                    actions.resetForm();
                    handleServerResponse(true, response.data.message);
                    toast(response.data.message || "Message Sent");
                }
                else {
                    const errorMessage = response.data.message || "Something went wrong. Please try again or use one of our other channels";
                    handleServerResponse(false, errorMessage);
                    toast(errorMessage, {});
                }
                actions.setSubmitting(false);
            })
            .catch((error) => {
                actions.setSubmitting(false);
                const errorMessage = error?.response?.data?.message || "Message Not Sent. Please try again or use one of our other channels";
                handleServerResponse(false, errorMessage);
                toast(errorMessage);
            });
    };

    return (
			<div className="support-container">
				<ToastContainer
					hideProgressBar={true}
					className="toast-container"
					toastStyle={{
						backgroundColor: `${
							serverState && !serverState.ok ? "#F8ECF3" : "#EBF7EE"
						}`,
						color: `${serverState && !serverState.ok ? "#C5046C" : "#22B231"}`,
						fontSize: "16px",
						paddingLeft: "20px",
					}}
				/>
				<NavBar logo={NavBarLogo} />
				<div className="support-intro container text-center">
					<div>
						<h1>We are here to help</h1>
						<p>Reach us directly.</p>
					</div>
				</div>
				<div className="support-contact container">
					<div className="row supportmobilereverse">
						<div className="col-lg-4 mb-5 supportdetails">
							<h4 className="text-center mb-4">Need to Speak to us?</h4>
							<Link to={{ pathname: Contacts.phone.link }} target="_blank">
								<Contact
									className="contact-info"
									contacticon={contactIconNumber}
									contactinfo={Contacts.phone.text}
								/>
							</Link>
							<Link to={{ pathname: Contacts.whatsapp.link }} target="_blank">
								<Contact
									className="contact-info"
									contacticon={contactIconWhatsapp}
									contactinfo={Contacts.whatsapp.text}
								/>
							</Link>
							<Link
								to={{
									pathname: Contacts.email.link,
								}}
								target="_blank"
							>
								<Contact
									className="contact-info"
									contacticon={contactIconEmail}
									contactinfo={Contacts.email.text}
								/>
							</Link>
						</div>

						<div className="col-lg-8 supportform center">
							<h4 className="mb-4 supportFormInfo">
								Send us a message and we will get back to you shortly. It will
								be our pleasure to answer any questions you may have.
							</h4>
							<div className="support-contact-form">
								<div className="support-contactForm">
									<Formik
										initialValues={{
											name: "",
											email: "",
											message: "",
										}}
										onSubmit={handleOnSubmit}
										enableReinitialize
									>
										{(formik) => (
											<Form>
												<div className="form-floating mb-4">
													<Field type="text" name="name">
														{({ field, meta: { touched, error } }) => (
															<Field
																className={
																	touched && error
																		? "invalid form-control"
																		: "form-control "
																}
																id="floatingName"
																placeholder="Name"
																{...field}
																style={{ paddingTop: 0 }}
																value={formik.values.name}
																required
																disabled={formik.isSubmitting}
															/>
														)}
													</Field>
													<label htmlFor="floatingName">
														<span>Name</span>
													</label>
												</div>
												<div className="form-floating mb-4">
													<Field type="email" name="email">
														{({ field, meta: { touched, error } }) => (
															<Field
																type="email"
																className={
																	touched && error
																		? "invalid form-control"
																		: "form-control "
																}
																required
																id="floatingEmail"
																placeholder="Email"
																{...field}
																style={{ paddingTop: 0 }}
																value={formik.values.email}
																disabled={formik.isSubmitting}
															/>
														)}
													</Field>

													<label htmlFor="floatingEmail">
														<span>Email</span>
													</label>
												</div>

												<div className="">
													<div className="form-floating">
														<Field name="message">
															{({ field, meta: { touched, error } }) => (
																<textarea
																	className={
																		touched && error
																			? "invalid form-control"
																			: "form-control "
																	}
																	{...field}
																	style={{
																		height: "200px",
																		paddingTop: "20px",
																	}}
																	placeholder="Write us a message"
																	id="floatingTextarea"
																	value={formik.values.message}
																	rows={10}
																	required
																	disabled={formik.isSubmitting}
																/>
															)}
														</Field>

														<label htmlFor="floatingTextarea">
															<span>Write us a message</span>
														</label>
													</div>
													<br />
													<div className="text-center appbutton mb-5 gap-2">
														<AppButton
															title={
																formik.isSubmitting
																	? "Sending..."
																	: "Send Us A Message"
															}
															className="btn btn-default btn-hero-button mb-5"
															type="submit"
															appbuttonSwitch
															disabled={
																formik.errors.name ||
																formik.errors.email ||
																formik.errors.message ||
																formik.isSubmitting
															}
														/>
														<br /> <br />
														<br /> <br />
													</div>
													{/* {serverState && (
                            <p className={!serverState.ok ? "errorMsg" : ""}>
                              {serverState.msg}
                            </p>
                          )} */}
												</div>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-5">
					<Section7 />
				</div>
				<Footer
					logo={NavBarLogo}
					appbuttontitle="Open an account"
					country="Nigeria"
				/>
			</div>
		);
}

export default SupportPage
