import React from "react";
import "../styles/tcard.scss";
function TCard({ message, photo, username, position, className }) {
  return (
    <div className={className}>
      <div className="d-flex gap-4 align-items-center tcard-info">
        <div className="">
          <img src={photo} alt=""/>
        </div>

        <div className="">
          <h5>{username}</h5>
          <p className="position">{position}</p>
        </div>
      </div>
      <p className="message">{message}</p>
    </div>
  );
}

export default TCard;
