import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import phoneIcon from "../assets/landingpages/phoneIcon1.svg";
// import whatsappIcon from "../assets/landingpages/whatsappIcon.svg";
import emailIcon from "../assets/landingpages/emailicon2.svg";
import { Contacts, Urls } from "../helper";
import defaultNavData from "../data/navigation"

const solutions = defaultNavData.find(({ id }) => id == "solutions")


const Footer3 = () => {
    return (
        <div className="footermobile">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Products
                        </button>
                    </h2>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <ul>
                                <li>
                                    <NavLink to="business-current-account">
                                        Current accounts
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="payouts">Payout</NavLink>
                                </li>

                                {/* <li>Payroll</li> */}
                                <li>
                                    <NavLink to="expense-management">Expense Management</NavLink>
                                </li>

                                <li>
                                    <NavLink to="bill-payment">Bill Payment </NavLink>
                                </li>

                                {/*<li>
                                    <NavLink to="growth">Growth </NavLink>
                                </li>*/}

                                {/*<li>
                                    <NavLink to="invoicing">Invoicing </NavLink>
                                </li>*/}

                                <li>
                                    <NavLink to="cards">Cards </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" pt-4 id="headingSolutions">
                        <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseHeadingSolutions"
                            aria-expanded="false"
                            aria-controls="collapseHeadingSolutions"
                        >
                            Solutions

                        </button>
                    </h2>
                    <div
                        id="collapseHeadingSolutions"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSolutions"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <ul>

                                {solutions.sub.map(({ name, link }) =>
                                    <li>
                                        <NavLink
                                            to={link}
                                            activeClassName="activeMobileNav"
                                        >
                                            {name}
                                        </NavLink>
                                    </li>

                                )}


                                {/* <li>Payroll</li> */}

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            Support
                        </button>
                    </h2>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <ul>
                                <Link to={{ pathname: Urls.blog }} target="_top">
                                    <li>Blog</li>
                                </Link>

                                <li>
                                    {" "}
                                    <NavLink to={{ pathname: Urls.faq }} target="_blank">
                                        FAQ{" "}
                                    </NavLink>
                                </li>

                                <li className="footer-contacts">
                                    <Link
                                        to={{ pathname: Contacts.email.link }}
                                        target="_top"
                                        className="text-nowrap"
                                    >
                                        <img
                                            src={emailIcon}
                                            width="30"
                                            height="30"
                                            alt="email"
                                            style={{ marginTop: "1px" }}
                                        />

                                        <span>{Contacts.email.text}</span>
                                    </Link>
                                </li>

                                <li className="footer-contacts">
                                    <Link
                                        to={{ pathname: Contacts.phone.link }}
                                        target="_blank"
                                        className="text-nowrap"
                                    >
                                        <img
                                            src={phoneIcon}
                                            width="30"
                                            height="30"
                                            alt="phone"
                                            style={{ marginTop: "1px" }}
                                        />

                                        <span>{Contacts.phone.text}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Company
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        style={{ height: "500px" }}
                    >
                        <div className="accordion-body">
                            <ul>
                                {/* <li>
                    <NavLink to="/about">About Us </NavLink>
                  </li> */}

                                {/* <li>Careers</li> */}
                                {/* <li>Customers</li> */}

                                <li>
                                    <Link to={{ pathname: Urls.privacy }} target="_blank" className="privacy">
                                        Privacy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: Urls.terms }} target="_blank" className="terms">
                                        Terms
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: Urls.careers }} target="_blank" className="careers">
                                        Careers
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Footer3;
