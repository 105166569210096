import React from "react";
import { Link } from "react-router-dom";
import "../../styles/social.scss"

function Social() {
  return (
    <div style={{ cursor: "pointer" }}>
      <Link
        to={{ pathname: process.env.REACT_APP_FACEBOOK_HANDLE }}
        target="_blank"
      >
        <i className="fab fa-facebook"></i>
      </Link>
      <Link
        to={{ pathname: process.env.REACT_APP_TWITTER_HANDLE }}
        target="_blank"
      >
        <i className="fab fa-twitter"></i>
      </Link>
      <Link
        to={{ pathname: process.env.REACT_APP_INSTAGRAM_HANDLE }}
        target="_blank"
      >
        {/* <i className="fab fa-instagram"></i> */}
        <i className="fab fa-instagram-square"></i>
      </Link>
      <Link
        to={{ pathname: process.env.REACT_APP_LINKEDIN_HANDLE }}
        target="_blank"
      >
        <i className="fab fa-linkedin-in"></i>
      </Link>
    </div>
  );
}

export default Social;
