import React from "react"
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

import applestorelogo from "../../assets/applestore.svg"
import googleplaylogo from "../../assets/playstore.svg"
// import applestorelogo1 from "../../assets/landingpages/applestorelogo1.svg";
// import googleplaylogo1 from "../../assets/landingpages/googleplaylogo1.svg";
// import MobileDevice from "../../assets/landingpages/MobileDevice.png";
import MobileDevice from "../../assets/landingpages/MobileDevices.svg";

import "../../styles/sections/section7.scss";
function Section7() {
  return (
    <section>
      <div className="container section-seven">

        <div className="align-items-center justify-content-center row">
          <div className="text-container">
            <h2 className="mobile-header1">
              Take your business with you anywhere you go
            </h2>

            <p>
              <b>Lenco business mobile app.</b> <br />
              Your business banking available to you on-the-go to handle everything
              from making and approving payments, to checking balances, seeing
              your business performance, sending invoices and lots more.
            </p>
            <div className="app">
              <Link
                to={{
                  pathname:
                    "https://apps.apple.com/us/app/lenco-banking/id1571311372",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <img src={applestorelogo} alt="" className="app-applestoreee" />
                {/* <img src={applestorelogo1} alt="" className="app-applestore1" /> */}
              </Link>
              <Link
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=co.lenco",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleplaylogo} alt="" className="app-playstoreee" />
                {/* <img src={googleplaylogo1} alt="" className="app-playstore1" /> */}
              </Link>
            </div>
          </div>
          <div className="image-container">
            <img src={MobileDevice} alt="" className="device" />
          </div>
        </div>

      </div>
    </section>
  );
}

export default Section7;
