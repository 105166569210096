import React, { useEffect } from "react";

import AppButton from "../Button";
import images from "../../assets/cards";
import SmartCards from "../SmartCards";
import Section10 from "./Section10";
import Slider from "react-slick"

const FeatureList = ({ features = [] }) => {

    return features.length > 0 &&
        <ul className='feature-list'>
            {features.map((feature) =>
                <li>
                    <i className="fa fa-check"/> <p>{feature}</p>
                </li>
            )
            }
        </ul>



}
const nameSpace = "smart-"



const SmartSplitters = ({ data = [] }) => {


    return data.length > 0 ?
        <div className={`${nameSpace}splitters`}>

            {data.map((props, i) => {
                return <SmartSplitter {...props} key={i} />

            })}

        </div>

        : null
}

const SmartSplitter = ({ image, title, description, ...others }) => {

    if (typeof description == 'object')
    {
        description = <FeatureList features={description} />
    }
    let newProps = {};

    newProps.title1 = title;
    newProps.description1 = description
    newProps.image2 = image;
    // newProps.className1 = "col-lg-6 me-auto col-md-6"
    // newProps.className2 = "col-lg-5 col-md-5"
    newProps.sectionten = "splitter"
    newProps.toggle1 = true
    newProps.toggle4 = true


    return <Section10
        {...newProps}

        {...others}

    />

}


const SmartBanner = ({
    title, description, action, extraProps =
    { image: null } }

) => {

    return <div className={`${nameSpace}banner`}>

        <div className="banner">

            <div>
                <h3>Get a full-fledged and powerful
                    business bank account</h3>
                <p>Lenco gives you a 10X better business account which fits perfectly into your business operations and needs.</p>
                <AppButton className="btn brand-primary" />
            </div>
        </div>

        <div className="banner-image">
            <img src={extraProps.image} />
        </div>
    </div>
}
const SmartBrands = ({ data = [], slider = false, extraProps = { parent: {} } }) => {


    console.log(extraProps)
    const Div = ({ children, ...others }) =>

        <div {...others}>
            {children}
        </div>



    const Parent = slider ? Slider : Div;

    return data.length > 0 ?
        <Parent className={`${nameSpace}brands brand align-items-center`} {...extraProps.parent}>

            {data.map(({ image, title }, i) => {
                return <div className="brand-image"><img src={image} key={i} alt={title} /></div>

            })}

        </Parent>

        : null
}



const SmartImages = ({ data = [] }) => {

    return data.length > 0 ?
        <div className={`${nameSpace}images row align-items-center`}>
            {data.map(({ image }) => {
                return <img src={image} />
            })}
        </div> : null





}


const SmartList = ({ dataType = "card", data = [], dataClassNames, slider = false, extraProps = { item: {}, parent: {}, image: null } }) => {


    switch (dataType)
    {

        case 'card':
            return <SmartCards data={data} dataClassNames={dataClassNames} extraProps={extraProps} />


        case 'brand':

            return <SmartBrands data={data} dataClassNames={dataClassNames} slider={slider} extraProps={extraProps} />

        case 'banner':

            return <SmartBanner data={data} dataClassNames={dataClassNames} extraProps={extraProps} />
        case 'splitter':

            return <SmartSplitters data={data} dataClassNames={dataClassNames} extraProps={extraProps} />
        case 'image':

            return <SmartImages data={data} dataClassNames={dataClassNames} extraProps={extraProps} />



        default:
            return <SmartCards data={data} dataClassNames={dataClassNames} slider={slider} />


    }
}

function SmartSection({ title, description, slider = false, className = "", classNames = { title: "", description: "" }, data = [], dataType = 'card',
    extraProps = { item: {}, image: null },
    dataClassNames = {
        parent: "",
        item: ""
    }, children }) {



    return (
        <section className={`container-fluid section-nine section-nine-bca ${nameSpace}section center ${className}`}>
            <div className="container no-padding section-nine-bca-container">
                {title && <h2 className={classNames.title}>{title}</h2>}
                {description && <p className={classNames.description}>{description}</p>}



                {((data.length > 0) || Object.keys(extraProps).length) && <SmartList dataType={dataType} data={data} extraProps={extraProps} slider={slider} />}
                {children}
            </div>
        </section>
    );
}


export { SmartBanner, SmartList, SmartCards }
export default SmartSection;
