import HeroImage from "../../assets/landingpages/payouts/heroImage.svg"
import images from "../../assets/landingpages/payouts"
import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
// const { dlvr, instadrop, truq, amani } = brandImages;


const { Apicodes, PayoutDashboard, UploadFile,
} = images;
export default {

    name: "Payouts",
    title: "Instant Payouts",
    description: "Instantly transfer money in bulk to bank accounts automatically or manually. Send instant bank transfers to your customers via APIs, file uploads or on the dashboard.",
    link: "/payouts",
    image: HeroImage,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [
        {


            dataType: "splitter",

            data: [
                {
                    title: "API Payout",
                    description: "Automate payouts to your customers with our well documented and simple to integrate API.",
                    image: Apicodes
                },
                {
                    title: "File Upload",
                    description: "Upload a .csv or .xlsx file and the recipients will be paid instantly.",
                    image: UploadFile
                },
                {
                    title: "Dashboard Payout",
                    description: "Add multiple recipients and pay all of them at once from the dashboard.",
                    image: PayoutDashboard
                }








            ]
        },



    ]

}