import React from 'react'
import PromotionSection from '../../components/sections/PromotionSection';
import PageTemplate from './PageTemplate';

function IndustryTemplate({ children, hiddenSections = [], ...others }) {
    return (
        <PageTemplate {...others} hiddenSections={hiddenSections}>
            {children}
            {!hiddenSections.includes('promotion') && <PromotionSection />}
        </PageTemplate>
    );
}

export default IndustryTemplate;
