import React from "react";
import "../styles/process.scss";
function Process2({ number, description }) {
  return (
    <div className="process text-center">
      <div className="">
        <h1 className="process-number">{number}</h1>
      </div>
      <div className="text-center">
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Process2;
