import React from 'react';
import {Helmet} from "react-helmet-async";
import {Contacts} from "../helper";

const HelmetMeta = ({country}) => {
    const baseDomain = process.env.REACT_APP_BASE_DOMAIN || "lenco.co";
    const baseUrl = process.env.REACT_APP_BASE_URL || "https://lenco.co";
    const emailAddress = Contacts.email.text || "support@lenco.co";
    return (
        <Helmet>
            <title>Banking and Expense management built for businesses in { country } | { baseDomain }</title>
            <meta name="description"
                  content={`Open a business bank account in ${country} for free. Email: ${emailAddress}`} />
            <meta itemProp="name" content={`Lenco ${country}`} />
            <meta itemProp="url" content={baseUrl} />
            <meta property="og:site_name" content={baseUrl} />
            <meta property="og:description"
                  content={`Open a business bank account in ${country} for free. Email: ${emailAddress}`} />
            <meta property="og:url" content={baseUrl} />
            <meta property="og:title"
                  content={`Banking and Expense management built for businesses in ${country} | ${baseDomain}`} />
            <meta property="og:type" content="Website" />
            <meta property="og:image" content={`${baseUrl}/assets/images/wallpaper.jpg`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@LencoHQ" />
            <meta name="twitter:creator" content="@LencoHQ" />
            <meta name="twitter:url" content={baseUrl} />
            <meta name="twitter:title"
                  content={`Banking and Expense management built for businesses in ${country} | ${baseDomain}`} />
            <meta name="twitter:image"
                  content={`${baseUrl}/assets/images/wallpaper.jpg`} />
            <meta name="twitter:description"
                  content={`Open a business bank account in ${country} for free. Email: ${emailAddress}`} />
            <meta name="author" content={baseUrl} />
            <link rel="publisher" href="https://plus.google.com/+LencoBank/" />
            <meta name="keywords"
                  content={`Business bank, Free bank, business bank in ${country}, Startup bank in ${country}, banking for startups, banks for hotels, business expense management, bank api in ${country}, bank for flutterwave, bank for paystack, expense management in ${country}, expense management and business expense management`} />
        </Helmet>
    );
};

export default HelmetMeta;
