import React from "react";
import Cardxl from "../Cardxl";
import "../../styles/sections/section11.scss";
import circleImage from "../../assets/landingpages/cards/circle.svg"
import featuredImage from "../../assets/landingpages/cards/phoneCard.svg";

function Section11() {
  return (
    <section className="container-fluid section-eleven">
      <div className="container">
        <div className="row align-items-center ">
          <div className="col-lg-7 ">
            <Cardxl />
          </div>
          <div className="col-lg-5 text-center featuredDevice ">
            <img
              src={featuredImage}
              alt="featuredImage"
              className="imghoverzoom"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section11;
