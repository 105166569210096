import SmartCard from "./SmartCard"
import Slider from "react-slick"
const SmartCards = ({ data = [], extraProps = { item: {}, parent: {} }, dataClassNames = { parent: "row", item: "col-lg-4 col-md-12" }, slider = false, nameSpace = "smart-" }) => {


    const Div = ({ children, ...others }) =>

        <div {...others}>
            {children}
        </div>



    const Parent = slider ? Slider : Div;

    return data.length > 0 ?


        <Parent className={dataClassNames.parent}  {...extraProps.parent}>

            {data.map(({ image, title, description }, i) => {

                return <div key={i} className={dataClassNames.item}  >

                    <SmartCard
                        nameSpace={nameSpace}
                        image={image}
                        title={title}
                        description={description}


                        {...extraProps.item}
                    />
                </ div>

            })}
        </Parent>

        : null


}


export default SmartCards;