import HeroImage from "../../assets/landingpages/retail/heroImage.svg"
import BannerImage from "../../assets/landingpages/retail/banner.svg"

import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
const { fastBills, groxie, merok, energyTraders } = brandImages;


const { merchant, onlineStore, pos } = cardIcons;
export default {



    name: "Retail",
    title: "You deserve more for your Retail Business than just a bank.",
    description: "Experience full access to all banking features and collect payments from your customers fast and seamlessly.",

    link: "/retail",


    image: HeroImage,
    showAppStore: true,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [
        {
            title: "Banking built for Retail Business",

            data: [
                {
                    title: "Optimal Digital Banking",
                    description: "Enjoy absolute convenience and 24/7 access to all banking functions and more.",
                    image: merchant
                },
                {
                    title: "Point-of-Sale Terminal",
                    description: "Collect card payments with fast and easy-to-use POS Terminal with same day settlement.",
                    image: pos
                },


                {
                    title: "Multi-outlet Management",
                    description: "Reconcile and manage sales at each of your outlets from a single dashboard",

                    image: onlineStore
                }







            ]
        },

        {
            title: "Lenco powers thousands of Retail Businesses like yours",
            dataType: "brand",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {

                    image: fastBills
                },
                {

                    image: groxie
                }, {

                    image: merok
                }, {

                    image: energyTraders
                }


            ]

        }
        ,
        {
            dataType: "banner",

            extraProps: {
                image: BannerImage
            }

        }

    ]

}