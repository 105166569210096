import HeroImage from "../../assets/landingpages/logistics/heroImage.svg"
import BannerImage from "../../assets/landingpages/logistics/banner.svg"

import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
const { dlvr, instadrop, truq, amani } = brandImages;


const { cashInHand, merchant, pos, creditCard } = cardIcons;
export default {

    name: "Logistics",
    title: "Better banking for your Logistics business",
    description: "Manage expenses on your fleets with an end-to-end solution tailored to your Logistics business.",
    link: "/logistics",
    image: HeroImage,
    showAppStore: true,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [
        {
            title: "Banking built for Logistics",

            data: [
                {
                    title: "Single & Bulk Payout",
                    description: "Make multi-currency payments with access to real exchange rate and no hidden fee.",
                    image: cashInHand
                },
                {
                    title: "Optimal Digital Banking",
                    description: "Enjoy absolute convenience and 24/7 access to all banking functions and more.",
                    image: merchant
                },


                {
                    title: "Virtual & Physical Cards",
                    description: "Issue cards to your Logistics partners for easier expense management.",

                    image: creditCard
                }






            ]
        },

        {
            title: "Lenco powers thousands of Logistics Business like yours",
            dataType: "brand",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {

                    image: dlvr
                },
                {

                    image: instadrop
                }, {

                    image: truq
                }, {

                    image: amani
                }


            ]

        }
        ,
        {
            dataType: "banner",

            extraProps: {
                image: BannerImage
            }

        }

    ]

}