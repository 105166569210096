import React from "react";
import "../../styles/sections/section5.scss";
import AppButton from "../Button";
import Process from "../Process";
import ProcessTwo from "../ProcessTwo";




const Next = () => {
  return <div className="next-process col-lg-1 col-md-1">

    <svg width="48" height="33" viewBox="0 0 48 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5H12.6602L28.2203 16.5L12.6602 32.5H0L15.5602 16.5L0 0.5ZM19.7799 32.5H32.44L48.0002 16.5L32.44 0.5H19.7799L35.34 16.5L19.7799 32.5Z" fill="#DDE1FE" />
    </svg>
  </div>

}

function Section5() {

  const processThree = (
    <>
      <span className="processThree1">
        Verify your business and <br /> personal identity
      </span>
      <span className="processThree2">
        Verify your business and personal identity
      </span>
    </>
  );


  const processFour = (
    <>
      <span className="processFour1">
        Your account is ready! <br /> Get started
      </span>
      <span className="processFour2">
        Your account is ready! Get started
      </span>
    </>
  );
  return (
    <section className="container-fluid section-five">
      <div className="container text-center">
        <h2 className="mobile-header">Get started with Lenco</h2>

        <div className="row process-container">
          <div className="col-lg-2 col-md-2  process-content">
            <Process number="1" description="Sign up in a few  minutes" />


          </div>


          <Next />

          <div className="col-lg-2 col-md-2 process-content">
            <Process number="2" description="Tell us about your business" />

          </div>
          <Next />
          <div className="col-lg-2 col-md-2 process-content">
            <Process number="3" description={processThree} />

          </div>
          <Next />
          <div className="col-lg-2 col-md-2 process-content">
            <ProcessTwo number="4" description={processFour} />
          </div>
        </div>
        <div className="text-center">

          <AppButton
            // title="Open an Account"
            className="btn btn-default btn-hero-button mt-5"
          />

        </div>
      </div>
    </section>
  );
}

export default Section5;
