import HeroImage from "./heroImage.svg";
import Reminder from "./reminder.svg"
import Robust from "./robust.svg"
import Transfer from "./transfer.svg"
import Collection from "./collection.svg"

export default {
    HeroImage,
    Reminder,
    Collection,
    Robust,
    // Reminder,
    Transfer
}
