import React, {useState} from "react";
import AppButton from "./Button";
import "../styles/subscribeform.scss";
// import SuccessModal from "./SuccessModal";
import {Field, Form, Formik} from "formik";
// import * as Yup from "yup";
import axios from "axios";

import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SubscribeForm({message}) {
    const [serverState, setServerState] = useState({ok: false, msg: ""});

    const handleServerResponse = (ok, msg) => {
        setServerState({ok, msg});
    };
    const handleOnSubmit = (values, actions) => {
        axios({
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + "/api/waitlist/join",
            data: values,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.data.success) {
                    actions.resetForm();
                    handleServerResponse(true, response.data.message);
                    toast(response.data.message || "You have joined the waitlist. We will be in touch!");
                }
                else {
                    const errorMessage = response.data.message || "Something went wrong. Please try again or contact support";
                    handleServerResponse(false, errorMessage);
                    toast(errorMessage, {});
                }
                actions.setSubmitting(false);
            })

            .catch((error) => {
                actions.setSubmitting(false);
                const errorMessage = error?.response?.data?.message || "Something went wrong. Please try again or contact support";
                handleServerResponse(false, errorMessage);
                toast(errorMessage);
            });
    };

    return (
        <>
            <div className="container subscribe-container mb-5">
                <ToastContainer
                    hideProgressBar={true}
                    className="toast-container"
                    toastStyle={{
                        backgroundColor: `${serverState && !serverState.ok ? "#F8ECF3" : "#EBF7EE"}`,
                        color: `${serverState && !serverState.ok ? "#C5046C" : "#22B231"}`,
                        fontSize: "16px",
                        paddingLeft: "20px",
                    }}
                />
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={handleOnSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <div className="input-group mb-5 subscribe-form">
                                <Field type="text" name="email">
                                    {({field, meta: {touched, error}}) => (
                                        <Field
                                            aria-describedby="button-addon2"
                                            className={
                                                touched && error
                                                    ? "invalid form-control"
                                                    : "form-control "
                                            }
                                            type="email"
                                            required
                                            disabled={formik.isSubmitting}
                                            id="floatingInput"
                                            placeholder="Your email.."
                                            {...field}
                                            style={{borderRadius: 8, color: "#fff"}}
                                            value={formik.values.email}
                                        />
                                    )}
                                </Field>
                                <AppButton
                                    type="submit"
                                    appbuttonSwitch
                                    title={formik.isSubmitting ? "Requesting..." : "Request an Invite"}
                                    className="btn btn-default btn-hero-button"
                                    // databstoggle={serverState && serverState.ok ? "modal" : ""}
                                    // databstarget="#staticBackdrop"
                                    disabled={formik.errors.email || formik.isSubmitting}
                                />
                            </div>
                            {/* <div>
                {serverState && (
                  <div className={!serverState.ok ? "errorMsg" : ""}>
                    {serverState.msg}
                  </div>
                )}

              </div> */}
                        </Form>
                    )}
                </Formik>

                {/*<SuccessModal message={message}/>*/}
            </div>
        </>
    );
}

export default SubscribeForm;
