import HeroImage from "../../assets/landingpages/hospitality/heroImage.svg"
import BannerImage from "../../assets/landingpages/hospitality/banner.svg"

import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
const { jayhiltin, hotelDotNg, spleet, saire } = brandImages;


const { money, cashBook, pos } = cardIcons;
export default {


    name: "Hospitality",
    title: "Manage payments & expenses seamlessly in your Hospitality business",
    description: "Run your hospitality business with an 10X better business account that understands you and your business needs.",
    link: "/hospitality",
    image: HeroImage,
    showAppStore: true,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [
        {
            title: "Banking built for Hospitality Business",

            data: [
                {
                    title: "Integrated Bank Transfer",
                    description: "Collect guest payment through bank transfer with instant transaction notifications and more.",
                    image: money
                },
                {
                    title: "Expense Management",
                    description: "Manage your hotel operational expenses & payments, attach receipts to transactions, and categorize your vendors easily.",
                    image: cashBook
                },


                {
                    title: "Point-of-Sale Terminal",
                    description: "Collect card payments with fast and easy-to-use POS Terminal with same day settlement.",
                    image: pos
                }







            ]
        },

        {
            title: "Lenco powers thousands of Hospitality Business like yours",
            dataType: "brand",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {

                    image: jayhiltin
                },
                {

                    image: hotelDotNg
                }, {

                    image: spleet
                }, {

                    image: saire
                }


            ]

        }
        ,
        {
            dataType: "banner",

            extraProps: {
                image: BannerImage
            }

        }

    ]

}