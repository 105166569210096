import React from "react";
import Social from "./sections/Social";
import "../styles/footer.scss";
import "../styles/footer2.scss";
import CountrySelect from "./CountrySelect";
import { NavLink } from "react-router-dom";

import footerLogo2 from "../assets/footer2logo.svg";

function Footer2({ flagsvg, country, country1link, country2link, country1, country2 , countryFlag1, countryFlag2}) {
  return (
    <div className="container-fluid footer2">
      <div className="footer2section-one">
        <div className="footerlogo2">
          <NavLink to="/">
            <img
              src={footerLogo2}
              alt=""
              className="text-center"
            />
          </NavLink>
          <p className="psmall logotagline ">Banking for modern busineses</p>

          <CountrySelect
            flagsvg={flagsvg}
            country={country}
            country1={country1}
            country2={country2}
            country1link={country1link}
            country2link={country2link}
            countryFlag1={countryFlag1}
            countryFlag2={countryFlag2}
          />
        </div>
        <div className="footer2copyright text-center">
          <p className="psmall">© Lenco Technologies, Inc. 2022</p>
        </div>
        <div>
          <div className="social">
            <Social />
          </div>
        </div>
      </div>
      <div className="footer2section-two">
        <div className="footer2section-two-logo">
          <img src={footerLogo2} alt="" />
          <p className="psmall logotagline">Banking for modern busineses</p>
          <CountrySelect
            flagsvg={flagsvg}
            country={country}
            country1={country1}
            country2={country2}
            country1link={country1link}
            country2link={country2link}
            countryFlag1={countryFlag1}
            countryFlag2={countryFlag2}
          />
        </div>
        <div className="footer2section-two-info">
          <div className="social">
            <Social />
          </div>
          <div className="footer2copyright text-center">
            <p>© Lenco Technologies, Inc. 2022</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer2;
