import React from "react";
import "../styles/contact.scss";

function Contact({ contacticon, contactinfo , className}) {
  return (
    <div className={className}>
      <img src={contacticon} alt="" />
      <p>{contactinfo}</p>
    </div>
  );
}

export default Contact;
