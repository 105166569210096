import SmartSection, { SmartCards } from "./SmartSection"

import images from "../../assets/cards";

const { teams, controls, report, expenseManagment, businessAccount } = images;


const Prev = (props) => {
    const { className, style, onClick } = props;
    return <span className={`slider-btn slider-btn-left `} onClick={onClick} style={style} >
        <i className="fa fa-chevron-left"/>
    </span >
}

const Next = (props) => {
    const { className, style, onClick } = props;
    return <span className={`slider-btn slider-btn-right`} onClick={onClick} style={style} >
        <i className="fa fa-chevron-right"/>
    </span >
}

const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    lazyLoad: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <Next />,
    prevArrow: <Prev />,
    appendDots: dots => (
        <div className="promotion-dot-container">
            <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
    ),
    customPaging: i => (
        <div className="promotion-dot">

        </div>
    ),

    responsive: [
        {
            breakpoint: 1200,
            settings: {
                // centerMode: true,
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                centerMode: false,
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 991,
            settings: {

                slidesToShow: 1,

            },
        },
    ],
};

const data = [
    {
        title: "Teams",
        description: "Invite, assign and take full control of the functions your team members can access and perform.",
        image: teams
    },
    {
        title: "Control",
        description: "Set instructions to auto-split inflows, restrict accounts from sending money out and lots more.",
        image: controls
    },
    {
        title: "Expense Management",
        description: "Manage your overall business expenses, categorize transactions, group recipients, and auto-share receipts",
        image: expenseManagment
    },

    {
        title: "Business Account",
        description: "Open a Lenco business bank account in 10 minutes and get your account activated in less than 48 hours.",
        image: businessAccount
    },
    {
        title: "Report",
        description: "Easily view your bank statements and download them anytime",
        image: report
    }

]

export default function PromotionSection() {


    return <SmartSection className="primary-tint-bg mb-5 pb-5">


        <SmartCards data={data} slider extraProps={{
            item: {
                center: true,
                imageType: "image",
                shadow: false
            },
            parent: {
                ...sliderSettings
            }

        }} />
    </SmartSection>
}
