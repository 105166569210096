import React from "react";
import "../../styles/sections/section12.scss";

function Section12({ title, description }) {
  return (
    <div className="container-fluid text-center section-twelve">
      <div className="container">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Section12;
