import startup from "./startup"
import hospitality from "./hospitality"
import retail from "./retail"
import logistics from "./logistics"
import payouts from "./payouts"
import businessCurrentAccount from "./businessCurrentAccount"
import home from "./home"
import billPayment from "./billPayment"
// import growth from "./growth"
import expenseManagement from "./expenseManagement"
import invoice from "./invoice"

import cards from "./cards"



const data = [
    home,
    startup,
    hospitality,
    retail,
    logistics,
    payouts,
    businessCurrentAccount,
    billPayment,
    // growth,
    expenseManagement,
    cards,
    invoice,



    {
        name: "Developer",
        title: "Integrate and make the most of Lenco API",
        description: "Automate payments, reconciliation, and perform thousands of transactions in seconds with our simple API.",

        link: "/developer"
    }




]

export default data
