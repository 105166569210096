import React from 'react'
import Hero from '../../components/Hero';
import NavBar from '../../components/NavBar';
import NavBarLogo from "../../assets/landingpages/lenco-logo_shtma7.svg";


import Footer from '../../components/Footer';
import Section8 from "../../components/sections/Section8"
import PromotionSection from '../../components/sections/PromotionSection';



function PageTemplate({ nameSpace = "",
    navigation = [],
    hero = {
        title: "", description: "",
        image: null,
        actions: [],
        showAppStore: true
    },

    children,
    hiddenSections = [],
    extraProps = {
        nav: {

        }

    } }) {




    return (
        <div className={`${nameSpace}-page solutions industry`}>

            <NavBar logo={NavBarLogo} data={navigation} />


            {(!hiddenSections.includes('hero') && hero.title) && <Hero
                title={hero.title}
                description={hero.description}
                image={hero.image}
                side1="col-lg-6"
                side2="col-lg-6 text-center"
                classImage="text-center animate__animated animate__fadeInRight animate__delay-1s heroImage"

                showAppStore={hero.showAppStore}
                actions={hero.actions}
            />}


            {children}

            {/* {!hiddenSections.includes('banner') && <SmartSection>
                <SmartBanner />
            </SmartSection>
            } */}

            {!hiddenSections.includes('faqs') && <Section8 />}


            {!hiddenSections.includes('footer') && <Footer
                logo={NavBarLogo}
                appbuttontitle="Open an account"
                country="Nigeria"
            />}


        </div>
    );
}

export default PageTemplate;
