
import Category from "./category.svg"
import Comment from "./comment.svg"
import Control from "./control.svg"
import Receipt from "./receipt.svg"
import HeroImage from "./HeroImage.svg"




export default {

    HeroImage,
    Comment,
    Control,
    Category,
    Receipt
}