import React from 'react'
import NavBarLogo from "../../assets/landingpages/lenco-logo_shtma7.svg";

import HeroImage from "../../assets/landingpages/developer/heroImage.svg";
import { Contacts, Urls } from "../../helper";
import "../../styles/pages/billpayment.scss"
import IndustryTemplate from '../../components/templates/IndustryTemplate';
import SmartSection from '../../components/sections/SmartSection';
import Section10 from '../../components/sections/Section10';

import images from "../../assets/cards";
import SmartFooter from '../../components/SmartFooter';
import navData from "../../data/navigation/developer"
import FeatureList from '../../components/FeatureList';
const { businessAutomation, virtualAccount, billPayment } = images;



function Developer() {







    return (

        <IndustryTemplate

            navigation={navData}
            nameSpace='developer'
            hiddenSections={[
                'promotion',
                'brands',
                'cards',
                'faqs',
                'banner',
                'footer'
            ]}

            hero={{
                title: "Integrate and make the most of Lenco API",
                image: HeroImage,
                showAppStore: false,
                description: "Automate payments, reconciliation, and perform thousands of transactions in seconds with our simple API.",
                actions:
                    [
                        { title: "Request Access", link: `${Contacts.devEmail.link}`, externalLink: true },
                        { title: "Explore Documentation", link: Urls.devUrl, className: "btn btn-hero-button btn-default mb-5 px-3 btn-ghost", iconClassName: "fa fa-arrow-right", externalLink: true }

                    ],
            }}

            extraProps={
                {
                    // nav: {
                    //     navTitle: "Developer"
                    // }
                }
            }
        >

            <SmartSection title="Why you should use Lenco API" classNames={{
                title: "text-center"
            }}>

                <Section10
                    title1="Business Automation"
                    description1={<FeatureList features={['Get robust business insights and analytics.', "Programmatically disburse funds to your Clients, Vendors, and Merchants."]} />}
                    image2={businessAutomation}
                    className1="col-lg-6 me-auto col-md-6"
                    className2="col-lg-5 col-md-5"
                    sectionten="section-ten-row row align-items-center colummnReverse"
                    toggle1={true}
                    toggle4={true}
                />
                <Section10
                    title2="Virtual Accounts"
                    description2={<FeatureList features={['Issue out virtual accounts to your Vendors, Merchants for seamless collection.']} />}
                    image1={virtualAccount}
                    className1="col-lg-5 col-md-5"
                    className2="col-lg-6 ms-auto col-md-6"
                    toggle2={true}
                    toggle3={true}
                />


                <Section10
                    title1="Bill Payment API"
                    description1={<FeatureList features={['Purchase Airtime, Power, Data, and Cable TV.', 'Sell bill payment services via the Lenco bill payment API.']} />}
                    image2={billPayment}
                    className1="col-lg-6 me-auto col-md-6"
                    className2="col-lg-5 col-md-5"
                    sectionten="section-ten-row row align-items-center colummnReverse"
                    toggle1={true}
                    toggle4={true}
                />
            </SmartSection>

            <SmartFooter logo={NavBarLogo} appbuttontitle="Open an account"
                country="Nigeria" />
        </IndustryTemplate>

    );
}

export default Developer;

