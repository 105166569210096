import React from "react";
import "../../styles/sections/section10.scss";
import AppButton from "../Button";

function Section10({
  title1,
  description1,
  title2,
  description2,
  image1,
  image2,
  className1,
  className2,
  className3,
  sectionten,
  sectionten1,
  descriptionBox,
  descriptionBox1,
  imageClass,
  toggle1,
  toggle2,
  toggle3,
  toggle4,
  section10Box,
  actions = []
}) {
  return (
    <div className="container-fluid section-ten">
      <div className="">
        <div
          className={
            sectionten && sectionten
              ? sectionten
              : "section-ten-row row align-items-center"
          }
        >
          <div className={className1}>
            <div
              className={
                sectionten1 && sectionten1 ? sectionten1 : "section-ten-one"
              }
            >
              {toggle1 === true ? (
                <div
                  className={
                    section10Box && section10Box
                      ? section10Box
                      : "section10Box1"
                  }
                >
                  <h3 className="mobile-header2">{title1}</h3>

                  <p>{description1}</p>
                  <div>{descriptionBox}</div>
                  {actions.length > 0 && actions.map(({
                    link, title, type, className = "btn-transparent ", iconClassName = "fa fa-arrow-right ", externalLink = false }, i) => {
                    return <AppButton
                      key={i}
                      title={title}
                      link={link}
                      type={type}
                      externalLink={externalLink}
                      iconClassName={iconClassName}

                      className={className ? className : 'btn btn-default btn-hero-button mb-5'}
                    />
                  })}
                </div>
              ) : (
                ""
              )}
              {toggle2 === true ? (
                <div
                  className={
                    section10Box && section10Box
                      ? section10Box
                      : "section10Box2"
                  }
                >
                  <img
                    src={image1}
                    alt=""
                    className={
                      imageClass && imageClass
                        ? imageClass
                        : "imghoverzoom text-center"
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={className2}>
            <div className="row align-items-center">
              <div className="section-ten-two">
                {toggle3 === true ? (
                  <div
                    className={
                      section10Box && section10Box
                        ? section10Box
                        : "section10Box3"
                    }
                  >
                    <h3 className="mobile-header2">{title2}</h3>
                    <p className={className3}>{description2}</p>
                    <div>{descriptionBox1}</div>


                  </div>
                ) : (
                  ""
                )}
                {toggle4 === true ? (
                  <div
                    className={
                      section10Box && section10Box
                        ? section10Box
                        : "section10Box4"
                    }
                  >
                    <img
                      src={image2}
                      alt=""
                      className={
                        imageClass && imageClass ? imageClass : "imghoverzoom text-center"
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section10;
