import {Urls} from "../../helper";

const data = [{
    name: "Developer",
    sub: [
        {
            name: "Account",
            link: Urls.apiUrlAccounts,
            externalLink: true
        },
        {
            name: "Recipients",
            link: Urls.apiUrlRecipients,
            externalLink: true
        },
        {
            name: "Banks",
            link: Urls.apiUrlBanks,
            externalLink: true
        },
        {
            name: "Resolve",
            link: Urls.apiUrlResolve,
            externalLink: true
        },
        {
            name: "Transactions",
            link: Urls.apiUrlTransactions,
            externalLink: true
        },




    ]
},
{
    name: "API",


    sub: [
        {
            name: "Virtual Account",
            link: Urls.apiUrlVirtualAccount,
            externalLink: true
        },
        {
            name: "Bill Payments",
            link: Urls.apiUrlBillPayment,
            externalLink: true
        },
        {
            name: "Webhooks",
            link: Urls.apiUrlWebhook,
            externalLink: true
        },
        {
            name: "Transactions",
            link: Urls.apiUrlTransactions,
            externalLink: true
        },
        {
            name: "Pricing",
            link: Urls.apiUrlPricing,
            externalLink: true
        },

    ]
}

]

export default data;
