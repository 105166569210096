import React from 'react'
import Section11 from '../sections/Section11';



import PageTemplate from './PageTemplate';



function CardTemplate({ children, hiddenSections = [], ...others }) {


    return (

        <PageTemplate {...others}>


            {children}
            <Section11 />


        </PageTemplate>

    );
}

export default CardTemplate;
