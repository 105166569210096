import React from "react";
import "../styles/cardxlinner.scss";
import cardxlinnerIcon from "../assets/landingpages/cards/checkIcon.svg"

function CardxlInner({ title, description }) {
  return (
    <div className="cardxlinner">
      <div className="d-flex cardxlinner-titlesection">
        <div>
          <img src={cardxlinnerIcon} alt="cardxlinnerIcon" height={26} width={26} />
        </div>
        <div>
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default CardxlInner;
