import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Urls } from "../helper";

import defaultNavData from "../data/navigation"

const solutions = defaultNavData.find(({ id }) => id == "solutions")

// console.log(solutions)
const NavBar3 = () => {
  return (
    <div className="navbarmobile">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingProducts">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseHeadingProducts"
              aria-expanded="false"
              aria-controls="collapseHeadingProducts"
            >
              Products
            </button>
          </h2>
          <div
            id="collapseHeadingProducts"
            className="accordion-collapse collapse"
            aria-labelledby="headingProducts"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ul>
                <li>
                  <NavLink
                    to="business-current-account"
                    activeClassName="activeMobileNav"
                  >
                    Current accounts
                  </NavLink>
                </li>
                <li>
                  <NavLink to="payouts" activeClassName="activeMobileNav">
                    Payout
                  </NavLink>
                </li>

                {/* <li>Payroll</li> */}
                <li>
                  <NavLink
                    to="expense-management"
                    activeClassName="activeMobileNav"
                  >
                    Expense Management
                  </NavLink>
                </li>

                <li>
                  <NavLink to="bill-payment" activeClassName="activeMobileNav">
                    Bill Payment{" "}
                  </NavLink>
                </li>

                {/*<li>
                  <NavLink to="growth" activeClassName="activeMobileNav">
                    Growth{" "}
                  </NavLink>
                </li>*/}

                {/*<li>
                  <NavLink to="invoicing" activeClassName="activeMobileNav">
                    Invoicing{" "}
                  </NavLink>
                </li>*/}

                <li>
                  <NavLink to="cards" activeClassName="activeMobileNav">
                    Cards{" "}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>





        <div className="accordion-item">
          <h2 className="accordion-header" pt-4 id="headingSolutions">
            <button
              className="accordion-button  collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseHeadingSolutions"
              aria-expanded="false"
              aria-controls="collapseHeadingSolutions"
            >
              Solutions

            </button>
          </h2>
          <div
            id="collapseHeadingSolutions"
            className="accordion-collapse collapse"
            aria-labelledby="headingSolutions"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ul>

                {solutions.sub.map(({ name, link }) =>
                  <li>
                    <NavLink
                      to={link}
                      activeClassName="activeMobileNav"
                    >
                      {name}
                    </NavLink>
                  </li>

                )}


                {/* <li>Payroll</li> */}

              </ul>
            </div>
          </div>
        </div>

        <p className="">
          <NavLink to="fees" activeClassName="activeMobileNav">
            Fees
          </NavLink>
        </p>

        <p>
          <NavLink to="support" activeClassName="activeMobileNav">
            Support
          </NavLink>
        </p>

        <p>

          <Link
            to={{ pathname: Urls.login, }}
            target="_top"
          >
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NavBar3;
