import NavData from "../pages"
const solutions = [
    "/hospitality",
    "/logistics",
    "/developer",
    "/retail",
    "/startup"

]
const data = [{
    id: "products",
    name: "Products",
    className: "col-lg-4 col-md-4 col-6",
    sub: [
        {
            name: "Business Current Account",
            title: "Real bank accounts for fast growing businesses",
            link: "/business-current-account"
        },
        {
            name: "Expense Management",
            title: "Experience the power of automated reconciliation.",
            link: "/expense-management"
        },
        // {
        //     name: "Growth",
        //     title: "Overdraft that supercharge your business growth.",
        //     link: "/growth"
        // },
        {
            name: "Payout",
            title: "Make automatic payments to your customers with no effort.",
            link: "/payouts"
        },
        {
            name: "Bill Payment",
            title: "Your business airtime and utility bills in one place. Built with your team in mind.",
            link: "/bill-payment"
        },
        {
            name: "Cards",
            title: "The Corporate Card for Startups & SMEs.",
            link: "/cards"
        },
        // {
        //     name: "Invoice",
        //     title: "At Lenco, billing customers is easy",
        //     link: "/invoice"
        // }



    ]
},
{
    id: "solutions",
    name: "Solutions",


    sub: NavData.filter(({ link }) => {

        return solutions.includes(link)

    })
},
{
    id: "fees",
    name: "Fees",
    link: "/fees"
}
]

export default data;