import images from "../../assets/landingpages/cards";

import cardIcons from "../../assets/icons"
import { Urls } from "../../helper";


const { HeroImage, FastAccount, TeamBanking, LinkedAccount, MultipleBusinesses, RobustBusiness, Convenient, CustomNotification } = images;

const { money, cashBook, pos } = cardIcons;
export default {


    name: "Business Current Account",
    title: "The Corporate Card for Startups & SMEs",
    description: "A card created to meet the needs of small and medium-sized businesses, from simple day-to-day expenses to international purchases.",
    link: "/cards",
    image: HeroImage,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ]


}