import React from 'react'
import "../../styles/sections/transferBanner.scss";
import TransferImage from "../../assets/transferImage.svg"
import AppButton from '../Button';
import FeatureList from '../FeatureList';
export default function TransferBanner() {
    return (
        <section className='transfer-banner'>
            <div className='container'>

                <div className='content'>
                    <div className='text-container'>
                        <h3>We are Free.</h3>
                        <FeatureList features={[
                            "No maintenance fee.",
                            "No minimum balance.",
                            "No hidden fees.",
                            "Free transfers with Lenco cash back",
                            "Free SMS and In-app notifications."
                        ]} />
                        <AppButton type="button" className="btn btn-light cta" iconClassName='fa fa-arrow-right' title="Learn More" />
                    </div>

                    <div className='image-container'>

                        <img src={TransferImage} />

                    </div>
                </div>



            </div>

        </section>

    )
}
