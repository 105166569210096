import React, {useEffect, useRef} from "react";
import Footer2 from "../../components/Footer2";
import HeroMain from "../../components/HeroMain";
import Navbar2 from "../../components/Navbar2";
import Section12 from "../../components/sections/Section12";
import Space from "../../components/Space";
import SubscribeForm from "../../components/SubscribeForm";
import Navbar2Logo from "../../assets/landingpages/waitlist/Group_705_zwd9ta.svg";
import "../../styles/sections/section6.scss";
import "../../styles/pages/waitlist.scss";
import AppButton from "../../components/Button";
import Slider from "react-slick";
import HelmetMeta from "../../components/HelmetMeta";

const HeromMainDescriptionTwoRender = (
    <>
        <div className="HeroMainDescription">
            Bank accounts <span> | </span> Payments <span> | </span> Expense management
        </div>
    </>
);


function WaitlistPage({
    pagetitle,
    pagename,
    heroImage,
    countryflagImage,
    countryflag,
    country1link,
    country2link,
    country1,
    country2,
    countryFlag1,
    countryFlag2,
    pageurl,
    country
}) {

    const useMountEffect = () => useEffect(function subscribeform() {
    }, []);

    const myRef = useRef(null);

    const executeScroll = () => myRef.current.scrollIntoView();

    useMountEffect(executeScroll);

    const message = `Tired of your business banking experience? Lenco banking is launching in ${pagename}. I'm on the waitlist, you should join too to get early access to a better business banking experience. Join now`;
    const settings = {
        className: "",
        infinite: true,
        fade: true,
        slidesToShow: 1,
        lazyLoad: true,
        speed: 1000,
        autoplay: true,
    };
    let HeroMainDescription = `Lenco is building a business banking software for ${pagename} businessess that
        is 10 times cheaper, 10 times better and 100% digital.`


    let SlidingText = [
        {
            title:
                "Do you hate ledger fees, maintenance fees, ridiculous charges and poor experience using your business bank account?",
            description: "",
        },
        {
            title: "Your bank charges you ledger fees?",
            description: "",
        },
        {
            title: "Your bank charges you account statement fees?",
            description: "",
        },
        {
            title: "Your business bank account has a minimum operating balance?",
            description: "",
        },
        {
            title: "You pay monthly charges on your business bank account?",
            description: "",
        },
        {
            title: "It takes days or weeks to open a business bank account?",
            description: "",
        },
        {
            title:
                "You get a terrible experience operating your business bank account?",
            description: "",
        },
        {
            title:
                "Your business bank account drowns your finance team in endless reconcilation and admin tasks?",
            description: "",
        },
    ];


    const SlidingTextItems = SlidingText.map((slidetext, index) => (
        <div className="item" key={index}>
            <Section12 title={slidetext.title} description={slidetext.description}/>
        </div>
    ));

    return (
        <div className="waitlist">
            <HelmetMeta country={pagename} />

            <Navbar2 logo={Navbar2Logo} onClick={executeScroll}/>
            <HeroMain
                description={HeroMainDescription}
                descriptiontwo={HeromMainDescriptionTwoRender}
                image={heroImage}
                className="container-fluid hero-main hero-main-dark"
                country={country}
            />

            <div className="section-12-2 landingpages" ref={myRef}>
                <p className="join-list">Be the first, Join our waiting list</p>
                <SubscribeForm message={message}/>
                <Space/>
            </div>
            <div>
                <h3 className="why-lenco mobile-header2 ">Why Lenco</h3>
                <Slider {...settings} className="">
                    {SlidingTextItems}
                </Slider>
                <p className="never-again">Never Again</p>
            </div>

            <div className="text-center">
                <AppButton
                    appbuttonSwitch={true}
                    title="Request an Invite"
                    className="btn btn-default btn-hero-button"
                    onClick={executeScroll}
                />
                <Space/>
            </div>

            <Footer2
                flagsvg={countryflagImage}
                country={countryflag}
                country1={country1}
                country2={country2}
                country1link={country1link}
                country2link={country2link}
                countryFlag1={countryFlag1}
                countryFlag2={countryFlag2}
            />
        </div>
    );
}

export default WaitlistPage
