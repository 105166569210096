import React from "react";
import Cardmd from "../Cardmd";
import "../../styles/sections/section9.scss";
import Cardmdimg1 from "../../assets/landingpages/bca/FastAccountOpening.svg";
import Cardmdimg2 from "../../assets/landingpages/bca/TeamBanking.svg";
import Cardmdimg3 from "../../assets/landingpages/bca/LinkedAccounts.svg";
// import Cardmdimg4 from "../../assets/landingpages/bca/Vector_4_q6itm7.svg";

function Section9() {
  return (
    <div className="container-fluid section-nine section-nine-bca">
      <div className="container section-nine-bca-container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <Cardmd
              image={Cardmdimg1}
              title="Fast Account Opening"
              description="It takes only 10mins to complete your 
account opening application. Your 
account gets approved and account 
number is issued within 24hrs."
            />
          </div>
          <div className="col-lg-4 col-md-12">
            <Cardmd
              image={Cardmdimg2}
              title="Team Banking"
              description="Add unlimited team members with 
custom access levels to your 
business bank account. Set who 
approves, initiates, views and 
manages payments."
            />
          </div>
          <div className="col-lg-4 col-md-12">
            <Cardmd
              image={Cardmdimg3}
              title="Linked Accounts"
              description="Do you own accounts with other banks? 
Connect your other bank account to 
Lenco for free. Get robust business
insights and analytics."
            />
          </div>
          {/* <div className="col-lg-3">
              <Cardmd
                image={
                  require("../../assets/landingpages/bca/Group_1_dg45yd.svg")
                    .default
                }
                title="Multiple Businesses."
                description="Do you own multiple 
              businesses? Manage all 
              your businesses on Lenco. 
              Simply switch between 
              businesses to manage 
              accounts."
              />
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default Section9;
