import React from "react";
import AppButton from "./Button";
import "../styles/hero.scss";
import "animate.css"

import appleStore from "../assets/applestore.svg"
import playStore from "../assets/playstore.svg"
import { Urls } from "../helper"
import FeatureList from "./FeatureList";


function Hero({ subtitle, subtitleClass, title, description, image, side1, side2, classImage, container, actions = [], showDefaultAction = true, showAppStore = false }) {
  return (
    <div className="container-fluid hero">


      <div className={container && container ? container : "container"}>
        <div className="row align-items-center content-container ">
          <div className={side1 && side1 ? side1 : "col-lg-6"}>
            <p
              className={
                subtitleClass && subtitleClass ? subtitleClass : "subtitle"
              }
            >
              {subtitle}
            </p>
            <h1>{title}</h1>
            <div className="pt-4 pb-4 herodescription mx-auto mx-lg-0">

              {typeof description == "object" ? <FeatureList features={description} /> : description}
            </div>
            <div className="hero-action-list">

              {(showDefaultAction && (!actions.length)) && <AppButton
                // title="Open an account"
                className="btn btn-default btn-hero-button mb-5"
              />
              }

              {actions.length > 0 && actions.map(({ link, title, type, className, iconClassName, externalLink = false }, i) => {
                return <AppButton
                  key={i}
                  title={title}
                  link={link}
                  type={type}
                  externalLink={externalLink}
                  iconClassName={iconClassName}

                  className={className ? className : 'btn btn-default btn-hero-button mb-5'}
                />
              })}

            </div>

            {showAppStore && <div className="appstore-link">
              <a href={Urls.appleStore} target="_blank"><img src={appleStore} alt="Apple Store Logo" /></a>
              <a href={Urls.playStore} target="_blank"><img src={playStore} alt="Google Play Store Logo" /></a>
            </div>
            }
          </div>

        </div>
      </div>

      <div className={side2 && side2 ? side2 + " hero-image-container" : "col-lg-6 text-center "}>
        <img
          src={image}
          alt=""
          className={
            classImage && classImage
              ? classImage
              : "text-center animate__animated animate__fadeInRight animate__delay-1s"
          }
        />
      </div>
    </div>
  );
}

export default Hero;
