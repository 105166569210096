import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/footer.scss";
import AppButton from "./Button";
import CountrySelect from "./CountrySelect";
import Social from "./sections/Social";
import CountrySelectFlag from "../assets/landingpages/Ellipse_1070_rojsue.svg";
import KenyaFlag from "../assets/landingpages/KenyaFlag.svg"
import RwandaFlag from "../assets/landingpages/RwandaFlag.svg"
import phoneIcon from "../assets/landingpages/phoneIcon1.svg";
// import whatsappIcon from "../assets/landingpages/whatsappIcon.svg";
import emailIcon from "../assets/landingpages/emailicon2.svg";
// import Footer3 from "./Footer3";
import { Contacts, Urls } from "../helper";

const Developer = () => {
    return <ul>
        {/* <li>
                    <NavLink to="/about">About Us </NavLink>
                  </li> */}

        {/* <li>Careers</li> */}
        {/* <li>Customers</li> */}

        <li>
            <Link to={{ pathname: Urls.apiUrlAccounts }} target="_blank" className="account">
                Accounts
            </Link>
        </li>
        <li>
            <Link to={{ pathname: Urls.apiUrlRecipients }} target="_blank" className="recipients">
                Recipients
            </Link>
        </li>
        <li>
            <Link to={{ pathname: Urls.apiUrlBanks }} target="_blank" className="banks">
                Banks
            </Link>
        </li>
        <li>
            <Link to={{ pathname: Urls.apiUrlResolve }} target="_blank" className="resolve">
                Resolve
            </Link>
        </li>
        <li>
            <Link to={{ pathname: Urls.apiUrlTransactions }} target="_blank" className="transactions">
                Transactions
            </Link>
        </li>
    </ul>
}
const Api = () => {
    return <ul>

        <li>
            <Link to={{ pathname: Urls.apiUrlVirtualAccount }} target="_blank">
                Virtual Account
            </Link>                                        </li>

        {/* <li>Payroll</li> */}
        <li>
            <Link to={{ pathname: Urls.apiUrlBillPayment }} target="_blank">
                Bill Payment
            </Link>
        </li>

        <li>
            <Link to={{ pathname: Urls.apiUrlWebhook }} target="_blank">
                Webhooks
            </Link>
        </li>
        <li>
            <Link to={{ pathname: Urls.apiUrlPricing }} target="_blank">
                Pricing
            </Link>
        </li>




    </ul>
}
const SmartAccordion = () => {
    return (
        <div className="footermobile">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Developer
                        </button>
                    </h2>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <Developer />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            API
                        </button>
                    </h2>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <Api />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            Support
                        </button>
                    </h2>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <ul>
                                <Link to={{ pathname: Urls.blog }} target="_top">
                                    <li>Blog</li>
                                </Link>

                                <li>
                                    {" "}
                                    <NavLink to={{ pathname: Urls.faq }} target="_blank">
                                        FAQ{" "}
                                    </NavLink>
                                </li>

                                <li className="footer-contacts">
                                    <Link
                                        to={{ pathname: Contacts.email.link }}
                                        target="_top"
                                        className="text-nowrap"
                                    >
                                        <img
                                            src={emailIcon}
                                            width="30"
                                            height="30"
                                            alt="email"
                                            style={{ marginTop: "1px" }}
                                        />

                                        <span>{Contacts.email.text}</span>
                                    </Link>
                                </li>

                                <li className="footer-contacts">
                                    <Link
                                        to={{ pathname: Contacts.phone.link }}
                                        target="_blank"
                                        className="text-nowrap"
                                    >
                                        <img
                                            src={phoneIcon}
                                            width="30"
                                            height="30"
                                            alt="phone"
                                            style={{ marginTop: "1px" }}
                                        />

                                        <span>{Contacts.phone.text}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Company
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        style={{ height: "500px" }}
                    >
                        <div className="accordion-body">
                            <ul>
                                {/* <li>
                    <NavLink to="/about">About Us </NavLink>
                  </li> */}

                                {/* <li>Careers</li> */}
                                {/* <li>Customers</li> */}

                                <li>
                                    <Link to={{ pathname: Urls.privacy }} target="_blank" className="privacy">
                                        Privacy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: Urls.terms }} target="_blank" className="terms">
                                        Terms
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: Urls.careers }} target="_blank" className="careers">
                                        Careers
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};



function SmartFooter({ logo, description, appbuttontitle, country, country1link, country2link, data = [] }) {
    const footerDescription = <>Open a business bank <br />account for FREE</>;
    return (
        <div className="container-fluid footer">
            <div className="container">
                <div className="row wrapper-inner">
                    <div className="col-lg-3 col-xl-4 col-md-7">
                        <div>
                            <div className="footer-mobile">
                                <div>
                                    <Link to="/" className="navbar-brand footer-mobile-logo">
                                        <img src={logo} alt="" />
                                    </Link>
                                    <p className="psmall">
                                        Banking services provided
                                        by ProvidusBank Plc
                                    </p>
                                </div>
                                <div>
                                    <CountrySelect
                                        flagsvg={CountrySelectFlag}
                                        country={country}
                                        country1="Kenya"
                                        country2="Rwanda"
                                        // country1link={country1link}
                                        // country2link={country2link}
                                        country1link="/ke"
                                        country2link="/rw"
                                        countryFlag1={KenyaFlag}
                                        countryFlag2={RwandaFlag}
                                    />
                                </div>
                            </div>

                            <h2 className="footerdescription">
                                {description && description ? description : footerDescription}
                            </h2>
                            <div className="app-mobile">
                                <AppButton
                                    // title={appbuttontitle}
                                    className=" btn btn-default btn-hero-button mb-5 footer-app-button"
                                // btn
                                // btn-default
                                // btn-default-footer
                                // mb-5
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-xl-8 col-md-5">
                        <div className="footerweb">
                            <div className="row footerwebrow">
                                <div className="col-lg-4 col-md-4 col-6">
                                    <h3>Developer</h3>

                                    <Developer />
                                </div>
                                <div className="col-lg-4 col-md-4 col-6">
                                    <h3>API</h3>
                                    <Api />
                                </div>

                                <div className="col-lg-4 col-md-4 col-12 footertoggle1">
                                    <h3>Support</h3>
                                    <ul>


                                        <li className="footer-contacts">
                                            <Link
                                                to={{ pathname: Contacts.email.link }}
                                                target="_top"
                                                className="text-nowrap"
                                            >
                                                <img
                                                    src={emailIcon}
                                                    width="30"
                                                    height="30"
                                                    alt="email"
                                                    style={{ marginTop: "1px" }}
                                                />

                                                <span>{Contacts.email.text}</span>
                                            </Link>
                                        </li>

                                        <li className="footer-contacts">
                                            <Link
                                                to={{ pathname: Contacts.phone.link }}
                                                target="_blank"
                                                className="text-nowrap"
                                            >
                                                <img
                                                    src={phoneIcon}
                                                    width="30"
                                                    height="30"
                                                    alt="phone"
                                                    style={{ marginTop: "1px" }}
                                                />

                                                <span>{Contacts.phone.text}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="social social-web">
                                        <Social />
                                    </div>
                                </div>
                            </div>


                        </div>

                        <SmartAccordion />
                    </div>
                </div>
                <div className="social social-mobile text-center">
                    <Social />
                </div>
            </div>
        </div>
    );
}

export default SmartFooter;
