import React, { useCallback, useEffect, useState } from 'react'


import siteData from '../data';

import SmartSection from '../components/sections/SmartSection';
import HomeTemplate from '../components/templates/Home';

import IndustryTemplate from '../components/templates/IndustryTemplate';
import PageTemplate from './templates/PageTemplate';
import CardTemplate from './templates/CardTemplate';



function Page({ data = { sections: [] }, ...others }) {

    const [pageName, setPageName] = useState('lenco');

    const [pageData, setData] = useState(data);
    const [navigation, setNavigation] = useState([]);

    const getData = useCallback(() => {

        const page = others?.location?.pathname
        return siteData.pages.find(({ link }) => {
            return link === page || (link + "/") === page
        })
    }, [others?.location?.pathname])



    useEffect(() => {

        const data = getData();

        setData(data);



        setPageName(data.name)

        setNavigation(siteData.navigation)

    }, [getData])

    if (!pageData)
    {
        return null;
    }

    let Template = PageTemplate;

    const templatName = others?.template;
    switch (templatName)
    {
        case "industry":

            Template = IndustryTemplate
            break;

        case "home":

            Template = HomeTemplate
            break;

        case "cards":

            Template = CardTemplate
            break;
        default: Template = PageTemplate

    }


    return (


        <Template
            nameSpace={pageName?.toLowerCase()}
            navigation={navigation}
            hero={{
                title: pageData?.title,
                description: pageData?.description,
                actions: pageData?.actions,

                showAppStore: pageData?.showAppStore,
                image: pageData?.image,

            }}


            {...others}
        >


            {pageData?.sections?.length > 0 && pageData.sections.map(({ title, ...others }, i) =>
                <SmartSection title={title} {...others} key={i} />
            )}





        </Template>
    );
}

export default Page;
