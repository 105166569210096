import React from "react";
import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import NavBar from "../../components/NavBar";
import Section10 from "../../components/sections/Section10";
import Section5 from "../../components/sections/Section5";
import Section8 from "../../components/sections/Section8";
import Section9 from "../../components/sections/Section9";
import NavBarLogo from "../../assets/landingpages/lenco-logo_shtma7.svg";
import HeroImage from "../../assets/landingpages/bca/HeroImage.svg";
import Section10Image from "../../assets/landingpages/bca/RobustBusinessImage.svg";
import Section10Image1 from "../../assets/landingpages/bca/ConvenientBureaucracyImage.svg";
import Section10Image2 from "../../assets/landingpages/bca/CustomNotificationImage.svg";
import Section10Image3 from "../../assets/landingpages/bca/MultipleBusinessImage.svg";
import "../../styles/pages/bca.scss";

//business current account
function BusinessCurrentAccountPage() {

    // const Section10Title = (
    //   <div>
    //     <div className="bca-title3">
    //       Convenient and <br /> Bureaucracy Free
    //     </div>
    //     <div className="bca-title3-mobile">Convenient and Bureaucracy Free</div>
    //   </div>
    // );
    const heroDescription = (
        <>
            <div className="bca-herodescription">
                Real bank accounts for fast growing businesses. <br/> These account
                accept all forms of deposits.
            </div>
            <div className="bca-herodescription-mobile">
                Real bank accounts for fast growing businesses. These account accept all
                forms of deposits.
            </div>
        </>
    );

    const accordion1Description = (
        <>
            <p>
                SMEs, startups, sole traders, and freelancers can open a business
                account with Lenco.
            </p>
            <p>
                We have 3 account types: <b>Starter</b>, <b>Pro</b> and <b>Prime</b>{" "}
                account.
            </p>
            <p>
                <b>Starter</b> accounts was built for sole traders and freelancers
                without a registered business.
            </p>
            <p>
                While <b>Pro</b> and <b>Prime</b> account are for registered business
                owners.
            </p>
        </>
    );


    return (
        <div className="bca-page">

            <NavBar logo={NavBarLogo}/>

            <Hero
                title="Free business current account"
                description={heroDescription}
                image={HeroImage}
                container="container heroContainer"
            />
            <Section9/>
            <Section10
                title2="Multiple Businesses"
                description2="Do you own multiple businesses? Manage all your businesses on Lenco.
                Simply switch between businesses to manage accounts."
                image1={Section10Image3}
                className1="col-lg-5 featuredBoxBG col-md-5"
                className2="col-lg-6 ms-auto col-md-6"
                toggle2={true}
                toggle3={true}
                imageClass="imghoverzoom text-center customImageSize"
            />
            <Section10
                title1="Robust Business Accounts"
                description1="Add comments to transactions, simple and easy to understand transaction history,
                filter and export account statements, attach invoices/receipts to transactions, auto-save recipients."
                image2={Section10Image}
                className1="col-lg-6 me-auto col-md-6"
                className2="col-lg-5 featuredBoxBG col-md-5"
                sectionten="section-ten-row row align-items-center colummnReverse"
                toggle1={true}
                toggle4={true}
            />
            <Section10
                title2="Convenient and Bureaucracy Free"
                description2="No paperwork. Do everything on the web or App.
                No hidden fees. Absolute control. We put everything in your hands.
                Best customer support whenever you need."
                image1={Section10Image1}
                className1="col-lg-5 col-md-5"
                className2="col-lg-6 ms-auto col-md-6"
                toggle2={true}
                toggle3={true}
                // section10Box="section10BoxCustom"
            />
            <Section10
                title1="Custom Notifications"
                description1="Customize the transaction notification received by your team.
                Grant full, truncated or no notifications. You can also give
                notification only access which is best for allowing employees
                at sales point receive inflow notification without your balances."
                image2={Section10Image2}
                className1="col-lg-6 me-auto col-md-6"
                className2="col-lg-5 featuredBoxBG col-md-5"
                toggle1={true}
                toggle4={true}
                sectionten="section-ten-row row align-items-center colummnReverse"
            />
            <Section8
                Button1="Who can open a business account?"
                Button2="I’m not in Nigeria, can I open an account?"
                Button3="Can I get an account statement for my business account?"
                Button4="I’m registered in another country, how do I open an account?"
                Accordion1={accordion1Description}
                Accordion2="Yes, if you have a registered business in Nigeria."
                Accordion3="Yes, you can. Every customer is entitled to their account statement at no cost."
                Accordion4="You need to be registered in Nigeria to open a business account."
            />
            <Section5/>
            <Footer
                logo={NavBarLogo}
                appbuttontitle="Open an account"
                country="Nigeria"
            />
        </div>
    );
}

export default BusinessCurrentAccountPage;
