import React from "react";
import "../styles/process.scss";
// import Hdash from "../assets/landingpages/Vector_932_fgdair.svg";
import Vdash from "../assets/landingpages/Vector_932_hgwh1a.svg";


function Process({ number, description }) {
  return (
    <div className="process text-center">
      <div className="">
        <h1 className="center">
          <span className="process-number">{number}</span>
          {/* <img
            src={
             Hdash
            }
            alt=""
            className="hdash"
          /> */}
          <img src={Vdash} alt="" className="vdash" />
        </h1>
      </div>
      <div className="">
        <p className="">{description}</p>
      </div>
    </div>
  );
}

export default Process;
