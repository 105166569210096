import React from "react";
// import { useHistory } from "react-router";
import "../styles/countryselect.scss";

import { Link } from "react-router-dom";

function CountrySelect({ flagsvg, country, country1, country2, country3, country1link, country2link, country3link, countryFlag1, countryFlag2 }) {
  // let history = useHistory();
  // function onChange(e) {
  //   history.push(`/${e.target.value}`);
  // }

  return (
    <div className="countryselect">
      <div className="countrySelectContainer">
        <div
          className="countryselectflagimg dropdown-toggle"
          // class=""
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={flagsvg} alt="" />{" "}
          <p style={{ paddingRight: "20px" }} className="countryname">
            {country}
          </p>
        </div>

        <ul className="dropdown-menu">
          <Link to={country1link} className="country-container country-item1">
            <div className="flag">
              <img src={countryFlag1} alt="" />
              <p>{country1}</p>
            </div>
          </Link>

          <Link to={country2link} className="country-container country-item2">
            <div className="flag">
              <img src={countryFlag2} alt="" />
              <p>{country2}</p>
            </div>
          </Link>
        </ul>
      </div>
      {/* <select
        className=""
        aria-label="form-select-sm example"
        value=""
        onChange={onChange}
      ></select> */}
    </div>
  );
}

export default CountrySelect;
