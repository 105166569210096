import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import { NavDropdown } from "react-bootstrap";
import "../styles/navbar.scss";
import AppButton from "./Button";
import Contact from "./Contact";
import phoneIcon from "../assets/landingpages/phoneIcon.svg";
import whatsappIcon from "../assets/landingpages/whatsappIcon.svg";
import emailicon1 from "../assets/landingpages/emailIcon.svg";
import NavBar3 from "./NavBar3";
import { Contacts, Urls } from "../helper";

import defaultNavData from "../data/navigation"


const Navigation = ({ data = [] }) => {




    return data.length > 0 && data.map(({ name, link, sub = [] }) => {

        const noSubText = () => {

            return sub.every(({ title }) => title)
        }



        return <li className="nav-item dropdown webnavbar">
            <Link
                to={link ? link : "/"}
                className={`nav-link ${link ? `` : "disable-pointer"}`}


            >
                {name}
            </Link>

            {sub.length > 0 && <ul
                className={`${noSubText() ? '' : 'small-submenu'} dropdown-menu navdropdown`}
                aria-labelledby="navbarDropdown"
            ><div className="row">

                    {sub.map(({ name, title, link, externalLink }) =>
                        <div className="col-lg-6 nav-dropdown-left">
                            <li>
                                {link && <Link
                                    to={externalLink ? { pathname: link } : link}
                                    className="dropdown-item"
                                    exact
                                    activeClassName="activeDropdownLink"

                                    target={externalLink ? "_blank" : "_self"}
                                >
                                    <b>{name}</b>
                                    {title && <p>
                                        {title}
                                    </p>
                                    }
                                </Link>
                                }
                            </li>

                        </div>
                    )}

                </div>
            </ul>

            }
            {sub.length > 0 && <i className="fa fa-chevron-down dropdown-icon" />}

        </li>
    })

}





function NavBar({ logo, navTitle = "", hideLeftMenu = false, data = [] }) {
    const [navData, setNavData] = useState(data);

    //  useEffect(() => {
    //    window.addEventListener("scroll", handleScroll);
    //    return () => window.removeEventListener("scroll", handleScroll);
    //  }, []);

    //  const handleScroll = () => {
    //    setShow(window.scrollY > 140);
    //   //  pageYOffset;
    //  };
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);

    // // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.scrollY > 550)
        {
            setSticky({ isSticky: true, offset: elHeight });
        } else
        {
            setSticky({ isSticky: false, offset: 0 });
        }
    };


    // const handleScroll = () => {
    //   if (window.scrollY > 140) {
    //     setSticky({ isSticky: true, offset: 140 });
    //   } else {
    //     setSticky({ isSticky: false, offset: 0 });
    //   }
    // };


    useEffect(() => {


        if (navData.length === 0)
        {
            setNavData(defaultNavData)


        }
    }, [])
    useEffect(() => {


        let header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height);
        };
        window.addEventListener("scroll", handleScrollEvent);

        return () => {
            window.removeEventListener("scroll", handleScrollEvent);
        };
    });

    return (
        // navigation-shadow
        <>
            <div style={{ marginTop: sticky.offset }}>
                {/* className=
        {sticky.isSticky
          ? "navbar navbar-expand-lg navbar-light navigation fixed-top navigation-shadow  "
          : "navbar navbar-expand-lg navbar-light navigation navigationbg"} */}
                <nav
                    className={`navbar navbar-expand-lg navbar-light navigation ${sticky.isSticky ? "fixed-top navigation-shadow" : "navigationbg"
                        }`}
                    ref={headerRef}
                >
                    <div className="container navwrapper mb-2">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="" />
                            {navTitle && <span className="navTitle"> {navTitle}</span>}

                        </Link>


                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            {/* <span class="navbar-toggler-icon"></span> */}
                            <span className="toggler-icon top-bar"/>
                            <span className="toggler-icon middle-bar"/>
                            <span className="toggler-icon bottom-bar"/>

                            {/* <button
                type="button"
                class="btn-close text-reset"
                aria-label="Close"
              ></button> */}
                        </button>

                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            {(!hideLeftMenu && navData.length > 0) && <ul className="navbar-nav me-auto mb-2 mb-lg-0">


                                <Navigation data={navData} />

                            </ul>
                            }


                            <ul className="navbar-nav mb-2 mb-lg-0 d-flex">
                                <li className="nav-item dropdown webnavbar">
                                    <Link
                                        to="/support"
                                        className="nav-link"
                                        exact
                                        activeClassName="activeLink"
                                    >
                                        Support
                                        <i className="fa fa-chevron-down dropdown-icon" />

                                    </Link>
                                    <ul
                                        className="dropdown-menu navdropdown supportdropdown"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 support1">
                                                <div className="">
                                                    <p className="pbold">We are here to help.</p>
                                                    <p>Reach us directly</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 support2 mt-4">
                                                <div className="">
                                                    <Link
                                                        to={{ pathname: Contacts.phone.link }}
                                                        target="_blank"
                                                    >
                                                        <Contact
                                                            className="supportcontactinfo"
                                                            contacticon={phoneIcon}
                                                            contactinfo={Contacts.phone.text}
                                                        />
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link
                                                        to={{ pathname: Contacts.whatsapp.link }}
                                                        target="_blank"
                                                    >
                                                        <Contact
                                                            className="supportcontactinfo"
                                                            contacticon={whatsappIcon}
                                                            contactinfo={Contacts.whatsapp.text}
                                                        />
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link
                                                        to={{ pathname: Contacts.email.link }}
                                                        target="_top"
                                                    >
                                                        <Contact
                                                            className="supportcontactinfo"
                                                            contacticon={emailicon1}
                                                            contactinfo={Contacts.email.text}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li className="nav-item webnavbar">
                                    <Link to={{ pathname: Urls.login }} target="_top" className="nav-link">
                                        Log In
                                    </Link>
                                </li>
                            </ul>


                            <AppButton
                                // title="Open an Account"
                                className="btn btn-default webnavbar"
                            />

                            <div className="mobilenavbar">
                                <NavBar3 />

                                <div className="d-grid gap-2 mobilenavbar-button mb-5">

                                    <AppButton
                                        // title="Open an Account"
                                        className="btn btn-default"
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default NavBar;
