import Anywhere from "./anywhere.svg"
import BusinessAccount from "./businessaccount.svg"
import Cards from "./cards.svg"
import ExpenseManagement from "./expensemanagment.svg"
import MaxSafe from "./maxsafe.svg"
import MaxSec from "./maxsec.svg"
import MaxTrust from "./maxtrust.svg"
import PayBills from "./paybills.svg"
import Payouts from "./payouts.svg"
import HeroImage from "./heroimage.svg"

import WeAreFree from "./wearefree.svg"




export default {

    Anywhere,
    BusinessAccount,
    Cards,
    ExpenseManagement,
    MaxSafe,
    MaxSec,
    MaxTrust,
    PayBills,
    Payouts,
    WeAreFree,
    HeroImage

}