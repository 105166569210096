import React from "react";
import { Link } from "react-router-dom";
import "../styles/button.scss";
import { Urls } from "../helper";

function AppButton({
    type,
    title,
    className,
    databstoggle,
    databstarget,
    disabled,
    onClick,
    link,
    externalLink = false,
    appbuttonSwitch,
    iconClassName = ""
}) {
    return (
        <>
            {appbuttonSwitch === true ? (
                <button
                    type={type}
                    className={className}
                    data-bs-toggle={databstoggle}
                    data-bs-target={databstarget}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {title && title ? title : "Open an Account"}
                </button>
            ) : (
                <Link
                    to={externalLink ? { pathname: link } : link || { pathname: Urls.signup }}
                    target='_top'
                    type={type}
                    className={className}
                    data-bs-toggle={databstoggle}
                    data-bs-target={databstarget}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {title && title ? title : "Open an Account"}
                    {iconClassName && <i className={iconClassName} />}

                </Link>
            )}
        </>
    );
}

export default AppButton;
