
import eziPay from "./ezipay.svg"
import giftedMum from "./giftedmum.svg"
import healthTracka from "./healthtracka.svg"
import dojah from "./dojah.svg"
import jayhiltin from "./jayhiltin.svg"
import hotelDotNg from "./hotelsdotng.svg"
import spleet from "./spleet.svg"
import saire from "./saire.svg"
import fastBills from "./fastbills.svg"
import groxie from "./groxie.svg"
import merok from "./merok.svg"
import energyTraders from "./engerytraders.svg"
import dlvr from "./dlvr.svg"
import truq from "./truq.svg"
import instadrop from "./instadrop.svg"
import amani from "./amani.svg"
import drinksDotNg from "./drinksdotng.svg"
import mejOil from "./menjoil.svg"
import remidialHealth from "./remidialhealth.svg"
import renda from "./renda.svg"
import vetPro from "./vetpro.svg"
import casava from "./casava.svg"
import clinify from "./clinify.svg"
import cowry from "./cowry.svg"











export default {

    eziPay,
    giftedMum,
    healthTracka,
    dojah,
    jayhiltin,
    hotelDotNg,
    spleet,
    saire,
    fastBills,
    groxie,
    merok,
    energyTraders,
    dlvr,
    instadrop,
    truq,
    amani,
    drinksDotNg,
    casava,
    cowry,
    clinify,
    mejOil,
    remidialHealth,
    renda,
    vetPro


}
