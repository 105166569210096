import cardSecurity from "./cardsecurity.svg"
import cashBook from "./cashbook.svg"
import creditCard from "./creditcard.svg"
import digitalBanking from "./digitalbanking.svg"
import cashInHand from "./cashinhand.svg"
import money from "./money.svg"
import moneyTransfer from "./moneytransfer.svg"
import onlineStore from "./onlinestore.svg"
import restApi from "./restapi.svg"
import pos from "./pos.svg"
import merchant from "./merchant.svg"




export default {
    cardSecurity,
    cashInHand,
    cashBook,
    creditCard,
    digitalBanking,
    money,
    moneyTransfer,
    onlineStore,
    restApi,
    pos,
    merchant

}


