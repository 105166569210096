const FeatureList = ({ features = [], iconClassName = "fa fa-check" }) => {

    return features.length > 0 &&
        <ul className='feature-list'>
            {features.map((feature) =>
                <li>
                    <i className={iconClassName}></i> <p>{feature}</p>
                </li>
            )
            }
        </ul>



}

export default FeatureList;