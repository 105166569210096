import React from 'react'
import NavBarLogo from "../../assets/landingpages/lenco-logo_shtma7.svg";
import AppButton from '../../components/Button';
import Footer from '../../components/Footer';
import NavBar from "../../components/NavBar";
// import Section3 from '../../components/sections/Section3';
import Section5 from '../../components/sections/Section5';
import "../../styles/pages/fees.scss"
import checkImage from "../../assets/landingpages/check.svg"
// import feesImage1 from "../../assets/landingpages/FeesImage1.svg";
// import feesImage2 from "../../assets/landingpages/FeesImage2.svg";
import OOOLogo from "../../assets/landingpages/000Logo.svg"
import ScrollAnimation from 'react-animate-on-scroll';

function FeesPage() {
    return (
        <div className="fees-container">

            <NavBar logo={NavBarLogo}/>
            <div className="fees-intro container">
                <div className="row  align-items-center">
                    <div className="col-lg-6">
                        <div>
                            <h1>It’s simple, FREE!</h1>
                            <p className="pt-4 pb-4">
                                No hidden fees, we love transparency. With Lenco, you always
                                know what you are paying for.
                            </p>

                            <AppButton
                                // title="Open an account"
                                className="btn btn-default btn-hero-button mb-5"
                            />

                        </div>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="OOOImage">
                            <img
                                src={OOOLogo}
                                alt="0.00"
                                className="animate__animated  animate__zoomIn"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fees-table container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <ScrollAnimation
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                        >
                            <div className="table1 mb-5">
                                <h2 className="fees-table-header mobile-header2">
                                    Payments
                                </h2>
                                <table className="table">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td colSpan="2">Minimum account balance</td>
                                        <td colSpan="" className="tablecustomTD">
                                            ₦0
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Cash withdrawal</td>
                                        <td colSpan="1" className="tablecustomTD">
                                            ₦0
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2">Debit card issuance</td>
                                        <td colSpan="1" className="tablecustomTD">
                                            ₦0
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Sub-account</td>
                                        <td colSpan="1" className="tablecustomTD">
                                            Unlimited
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">Bank transfer</td>
                                        <td colSpan="2" className="tablecustomTD">
                                            Free<span> (100% Cashback on transfer fee)</span>
                                            {/* <img src={feesImage1} alt="free" /> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"> Legal search fee (one-off)</td>
                                        <td colSpan="1" className="tablecustomTD">
                                            ₦10,000{" "}
                                            <span>(one time payment on prime accounts)</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                            delay={200}
                        >
                            <div className="table1 mb-5">
                                <h2 className="fees-table-header mobile-header2 ">
                                    Business Tools
                                </h2>
                                <table className="table">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td>Current account</td>
                                        <td>
                                            <img
                                                src={checkImage}
                                                alt="check"
                                                className="tablecustomTD1"
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>API access</td>

                                        <td>
                                            <img
                                                src={checkImage}
                                                alt="check"
                                                className="tablecustomTD1"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Team control</td>

                                        <td>
                                            <img
                                                src={checkImage}
                                                alt="check"
                                                className="tablecustomTD1"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Account analytics</td>

                                        <td>
                                            <img
                                                src={checkImage}
                                                alt="check"
                                                className="tablecustomTD1"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Bill payment</td>

                                        <td>
                                            <img
                                                src={checkImage}
                                                alt="check"
                                                className="tablecustomTD1"
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div>
                <Section5/>
            </div>
            <Footer
                logo={NavBarLogo}
                appbuttontitle="Open an account"
                country="Nigeria"
            />
        </div>
    );
}

export default FeesPage
