import React from "react";
import "../styles/heromain.scss";
import AppButton from "./Button";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";

import Typewriter from "typewriter-effect";





function HeroMain({ description, descriptiontwo, image, image1, image2, image3, imageMedium, className, country }) {



  return (
    <div className={className}>
      <div className="container text-center hero-main-wrapper">
        <div className="row align-items-center">
          <p className="hero-subtext">Meet Lenco</p>
          <div className="h1-one">
            <h1 className="">
              All-in-one finance <br />
              {/* {country} */}
            </h1>
            <div className="hero-typingText">
              <div>built for </div>
              <Typewriter
                options={{
                  cursor: "",
                  delay: "natural",
                  pauseFor: 3000,

                  strings: [
                    "Tech startups",
                    "Sole traders",
                    "SMEs",
                    "Freelancers",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </div>

          <div className="h1-two">
            <h1 className="">
              All-in-one finance <br />
              {/* {country} */}
            </h1>
            <div className="hero-typingText">
              <div>built for </div>
              <Typewriter
                options={{
                  cursor: "",
                  delay: "natural",
                  pauseFor: 3000,

                  strings: [
                    "Tech startups",
                    "Sole traders",
                    "SMEs",
                    "Freelancers",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </div>

          <div className="intro">
            <p className="">{/* {description} */}</p>
            <span className="">{descriptiontwo}</span>
          </div>
          <div className="mb-5">
            <AppButton
              // title="Open an account"
              className="btn btn-default btn-hero-button"
            />
          </div>

          <br />
          <br />
          <br />
          <br />

          <div className="hero-featured">
            <div className="hero-featured-sm-one hero-sm">
              <ScrollAnimation animateIn="animate__fadeInRight">
                <img src={image1} alt="" />
              </ScrollAnimation>
            </div>

            <img src={image} alt="" className="minMedium" />
            {/* <img src={imageMedium} alt="" className="maxMedium" /> */}

            <div className="hero-featured-sm-two hero-sm">
              <ScrollAnimation animateIn="animate__fadeInUp">
                <img src={image2} alt="" />
              </ScrollAnimation>
            </div>
            <div className="hero-featured-sm-three hero-sm">
              <ScrollAnimation animateIn="animate__fadeInUp">
                <img src={image3} alt="" />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroMain;
