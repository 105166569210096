import React from "react";
import "../styles/accordion.scss";
function Accordion({button1, accordion1, button2, accordion2, button3, accordion3, button4, accordion4}) {

const accordion2Description = (
  <>
    <p>
      SMEs, startups, sole traders, and freelancers can open a business account
      with Lenco.
    </p>
    <p>
      We have 3 account types: <b>Starter</b>, <b>Pro</b> and <b>Prime</b>{" "}
      account.
    </p>
    <p>
      <b>Starter</b> accounts was built for sole traders and freelancers without
      a registered business.
    </p>
    <p>
      While <b>Pro</b> and <b>Prime</b> account are for registered business
      owners.
    </p>
  </>
);
  return (

    
    <div className="accordioncontainer">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              {button1 && button1 ? button1 : "What is Lenco?"}
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {accordion1 && accordion1
                ? accordion1
                : "Lenco is a digital only bank that issues easy to open and free to operate current bank accounts for Startups and SMEs without any hassle."}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              {button2 && button2
                ? button2
                : "Who can open a business account?"}
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {accordion2 && accordion2
                ? accordion2
                : accordion2Description}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              {button3 && button3
                ? button3
                : "Is there a limit to the number of accounts I can have?"}
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {accordion3 && accordion3
                ? accordion3
                : "Each business is eligible to have one account. You can create multiple sub-accounts to manage the different needs of your business. If you own multiple businesses, you can add a new business directly from your dashboard. This will enable you to manage all you businesses from a single dashboard by switching which business you want to manage per time."}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              {button4 && button4
                ? button4
                : "How much does it cost to operate a Lenco account?"}
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {accordion4 && accordion4
                ? accordion4
                : "Opening and operating a Lenco account is free! No transfer fees (you get cashback), No account maintenance fee, No minimum balance and more."}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
