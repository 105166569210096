import images from "../../assets/landingpages/expensemanagement"


import { Urls } from "../../helper";


const { Category, HeroImage, Comment, Control, Receipt } = images;


export default {


    name: "Expense Management",
    title: "Automated Expense Management",
    description: "Add receipts, set approval levels, add comments, categorize transactions, group recipients, and many more",
    link: "/expense-management",
    image: HeroImage,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [

        {
            dataType: "splitter",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {
                    title: "Be in control of your Business spend",
                    description: "You get instant access to draw from your overdraft when your opt in request is approved. We understand your capital needs and will give you the funds to meet them.",
                    image: Control
                },

                {
                    title: "Add Receipts for Expenses",
                    description: "Upload receipts the moment you make a payment. Save your finance team hundreds of hours on reconciliation every month.",
                    image: Receipt
                },
                {
                    title: "Add comments to Transactions",
                    description: "Have you made transactions and you can’t remember why? Add comments to transactions to keep your team informed. Add multiple comments at anytime.",
                    image: Comment
                },
                {
                    title: "Categorize Expenses",
                    description: "Add expense categories for all payment. Your expenses auto-reconciles each month and you save hundred of hours.",
                    image: Category
                }






            ]

        }


    ]

}
