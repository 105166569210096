import React, { useState } from "react";
import "../../styles/sections/section6.scss";
import TCard from "../TCard";
import Photo1 from "../../assets/landingpages/testimonials/femi fawole1.svg";
import Photo2 from "../../assets/landingpages/testimonials/mark essien.svg";
import Photo3 from "../../assets/landingpages/testimonials/George Odiana.svg";
import Photo4 from "../../assets/landingpages/testimonials/Mr Michael Oboh Peters.jpeg";
import Photo5 from "../../assets/landingpages/testimonials/Dr. Femi Kayode.png";
import Photo6 from "../../assets/landingpages/testimonials/Chekwas Okafor.png";
import Photo7 from "../../assets/landingpages/testimonials/Solomon Ezoguan.png";
import Module from "../../assets/landingpages/testimonials/Module.svg";
import Mega from "../../assets/landingpages/testimonials/Mega.svg";
import Nnamdi from "../../assets/landingpages/testimonials/Nnamdi.svg";
import Nweke from "../../assets/landingpages/testimonials/Nweke.svg";
import Bola from "../../assets/landingpages/testimonials/Bola.svg";
import James from "../../assets/landingpages/testimonials/James.svg";
import Abiodun from "../../assets/landingpages/testimonials/Abiodun.svg";
import Goodwill from "../../assets/landingpages/testimonials/Goodwill.svg";

import Victory from "../../assets/landingpages/testimonials/Victory.svg";




import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




function Section6() {

  const settings = {
    infinite: true,
    slidesToShow: 3,
    lazyLoad: true,

    useTransform: true,
    autoplay: true,
    autoplaySpeed: 2000,

    cssEase: 'linear',


    speed: 9000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          // centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {

          slidesToShow: 1,

        },
      },
    ],
  };


  const reviewData = [
    {
      username: "Solomon Ezoguan",
      position: "Bitmessa Technologies",
      photo: Photo7,
      message:
        "The user interface is intuitive and straightforward. The customer support offers a white-glove service with real humans that are quick to reach, creating a feeling like they are a part of your office. Lenco also offers free running costs and cashback on all monthly expenses.",
    },
    {
      username: "Chekwas Okafor",
      position: "Merok Clothing",
      photo: Photo6,
      message:
        "My experience with Lenco has been incredibly pleasing. Think of all the hassle of banking with traditional banks. Then think of not having any of those hassles. That’s the experience when banking with Lenco.",
    },
    {
      username: "Dr. Femi Kayode",
      position: "Vetpro Veterinary Services",
      photo: Photo5,
      message:
        "Lenco is and has been an amazing tool my team and I have used and still using to help us straighten and correct our transaction's loop holes.  I recommend Lenco to all business.",
    },
    {
      username: "Mr Michael Oboh Peters",
      position: "Jay Hiltin Hotel & Suites",
      photo: Photo4,
      message:
        "Lenco as a financial partner has driven my business performance and examination of the company's financial statements reviews. Gives transactions ease across all sale and expenses.",
    },
    {
      username: "Mark Essien",
      position: "Hotels.ng",
      photo: Photo2,
      message:
        "Lenco is the best banking and finance software I have used. The experience has been beyond awesome. I have used most of the business banking/finance product in the market and none of them come close to the quality of product the team at Lenco has built.",
    },
    {
      username: "George Odiana",
      position: "Xoda Africa Limited",
      photo: Photo3,
      message:
        "Opening a current account has been a chore, You Guys are solving a real problem. Keep up the Good work and great customer service.",
    },
    {
      username: "Femi Fawole",
      position: "Brown Cedar",
      photo: Photo1,
      message:
        "I have tried two others before Lenco came on board. The first one did not get back to me and the second has been trying to get me started for 3 months. You have made it easy for us to reconcile accounts.",
    },

    // {
    //   username: "George Odiana",
    //   position: "Xoda Africa Limited",
    //   photo: Photo3,
    //   message:
    //     "Opening a current account has been a chore, You Guys are solving a real problem. Keep up the Good work and great customer service.",
    // },
    // {
    //   username: "Femi Fawole",
    //   position: "Brown Cedar",
    //   photo: Photo1,
    //   message:
    //     "I have tried two others before Lenco came on board. The first one did not get back to me and the second has been trying to get me started for 3 months. You have made it easy for us to reconcile accounts.",
    // },
    {
      username: "Module Macaulay",
      position: "Google Playstore",
      photo: Module,
      message:
        "I have absolutely no complaints about Lenco. It's quick and easy to set up a bank account, the app is easy to use, and customer support is fantastic. It's become my primary business account.",
    },

    {
      username: "Mega Glory",
      position: "Google Playstore",
      photo: Mega,
      message: "Wow , thanks to Lenco & the team , my account is active and smooth services. Customer care services so superb in terms of all communication channels to reach out to the clients with professional approach."
    },
    {
      username: "Nnamdi Chima-Dike",
      position: "Google Playstore",
      photo: Nnamdi,
      message: "Very amazing experience. Transfers and notification are fast. Many great features good for my business."
    },

    {
      username: "Nweke Kingsley",
      position: "Google Playstore",
      photo: Nweke,
      message: "Customer support, 100%. Best so far in terms of transactions authorization and notifications."
    },

    {
      username: "James Ayim-odu",
      position: "Google Playstore",
      photo: James,
      message: "I am so loving this app and most interesting is the customer service they are up and doing please keep it up."
    },
    {
      username: "Abiodun Awoyemi",
      position: "Google Playstore",
      photo: Abiodun,
      message: "This business banking app is awesome. Easy to set up and friendly interface. Able to monitor my transactions, the experience has been great. Good job team Lenco."
    },
    {
      username: "Victory Thompson",
      position: "Google Playstore",
      photo: Victory,
      message: "An innovative and creative E- banking platform with great objectives and structure. Easily accessible to all and sundry."
    },
    {
      username: "Bola Akogun",
      position: "Google Playstore",
      photo: Bola,
      message: "This is indeed 'better banking built for businesses'. It gives me full control my account and over the access I give to team members with customisable options."
    },

    {
      username: "Goodwill Patrick",
      position: "Google Playstore",
      photo: Goodwill,
      message: "Lenco is a great app to have..They are very fast and reliable . What I love most is their customer care service, it's top notch . It doesn't take 10 minutes to resolve an issue. They are very responsive."
    },



  ];

  const [review, setReview] = useState(reviewData);

  return (
    <section className="container-fluid section-six">
      <div className=" section-six-wrapper">
        <div className="row align-items-center">
          <h2 className="mobile-header">
            A banking experience businesses love
          </h2>
          <Slider {...settings} className="review-carousel">
            {setReview &&
              review.map((data, index) => (
                <div key={index} className="review-item">
                  <TCard
                    message={data.message}
                    photo={data.photo}
                    username={data.username}
                    position={data.position}
                    className="tcard"
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Section6;
