import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/footer.scss";
import AppButton from "./Button";
import CountrySelect from "./CountrySelect";
import Social from "./sections/Social";
import CountrySelectFlag from "../assets/landingpages/Ellipse_1070_rojsue.svg";
import KenyaFlag from "../assets/landingpages/KenyaFlag.svg"
import RwandaFlag from "../assets/landingpages/RwandaFlag.svg"
import phoneIcon from "../assets/landingpages/phoneIcon1.svg";
// import whatsappIcon from "../assets/landingpages/whatsappIcon.svg";
import emailIcon from "../assets/landingpages/emailicon2.svg";
import Footer3 from "./Footer3";
import { Contacts, Urls } from "../helper";
import navData from "../data/navigation"




const Navigation = ({ data = [] }) => {
    return (data.map(({ name, className = "col-lg-3 col-md-3 col-6", sub = [] }) => {


        return sub.length > 0 && <div className={className}>
            <h3>{name}</h3>

            {sub.length > 0 && <ul>
                {sub.map(({ link, name }) => <li>
                    <NavLink to={link}>{name} </NavLink>
                </li>)}
            </ul>}

        </div>
    })







    )
}

function Footer({ logo, description, appbuttontitle, country, country1link, country2link }) {
    const footerDescription = <>Open a business bank <br />account for FREE</>;
    return (
        <div className="container-fluid footer">
            <div className="container">
                <div className="row wrapper-inner">
                    <div className="col-lg-3 col-xl-3 col-md-7">
                        <div>
                            <div className="footer-mobile">
                                <div>
                                    <Link to="/" className="navbar-brand footer-mobile-logo">
                                        <img src={logo} alt="" />
                                    </Link>
                                    <p className="psmall">
                                        Banking services provided by Providus Bank and Ure Micro Finance Bank.
                                    </p>
                                </div>
                                <div>
                                    <CountrySelect
                                        flagsvg={CountrySelectFlag}
                                        country={country}
                                        country1="Kenya"
                                        country2="Rwanda"
                                        // country1link={country1link}
                                        // country2link={country2link}
                                        country1link="/ke"
                                        country2link="/rw"
                                        countryFlag1={KenyaFlag}
                                        countryFlag2={RwandaFlag}
                                    />
                                </div>
                            </div>

                            <h2 className="footerdescription">
                                {description && description ? description : footerDescription}
                            </h2>
                            <div className="app-mobile">
                                <AppButton
                                    // title={appbuttontitle}
                                    className=" btn btn-default btn-hero-button mb-5 footer-app-button"
                                // btn
                                // btn-default
                                // btn-default-footer
                                // mb-5
                                />
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-9 col-xl-9 col-md-5">
                        <div className="footerweb">
                            <div className="row footerwebrow">
                                <div className="col-lg-2 col-md-2 col-6">
                                    <h3>Company</h3>
                                    <ul>
                                        {/* <li>
                    <NavLink to="/about">About Us </NavLink>
                  </li> */}

                                        {/* <li>Careers</li> */}
                                        {/* <li>Customers</li> */}

                                        <li>
                                            <Link to={{ pathname: Urls.privacy }} target="_blank" className="privacy">
                                                Privacy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: Urls.terms }} target="_blank" className="terms">
                                                Terms
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: Urls.careers }} target="_blank" className="terms">
                                                Careers
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <Navigation data={navData} />

                                <div className="col-lg-3 col-md-3 col-12 footertoggle1">
                                    <h3>Support</h3>
                                    <ul>
                                        <Link to={{ pathname: Urls.blog }} target="_top">
                                            <li>Blog</li>
                                        </Link>

                                        <li>
                                            {" "}
                                            <NavLink to={{ pathname: Urls.faq }} target="_blank">
                                                FAQ{" "}
                                            </NavLink>
                                        </li>

                                        <li className="footer-contacts">
                                            <Link
                                                to={{ pathname: Contacts.email.link }}
                                                target="_top"
                                                className="text-nowrap"
                                            >
                                                <img
                                                    src={emailIcon}
                                                    width="30"
                                                    height="30"
                                                    alt="email"
                                                    style={{ marginTop: "1px" }}
                                                />

                                                <span>{Contacts.email.text}</span>
                                            </Link>
                                        </li>

                                        <li className="footer-contacts">
                                            <Link
                                                to={{ pathname: Contacts.phone.link }}
                                                target="_blank"
                                                className="text-nowrap"
                                            >
                                                <img
                                                    src={phoneIcon}
                                                    width="30"
                                                    height="30"
                                                    alt="phone"
                                                    style={{ marginTop: "1px" }}
                                                />

                                                <span>{Contacts.phone.text}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="social social-web">
                                        <Social />
                                    </div>
                                </div>
                            </div>


                            <div className="footertoggle2">
                                <h3>Support</h3>
                                <ul>
                                    <Link to={{ pathname: Urls.blog }} target="_top">
                                        <li>Blog</li>
                                    </Link>

                                    <li>
                                        {" "}
                                        <NavLink to={{ pathname: Urls.faq }} target="_blank">
                                            FAQ{" "}
                                        </NavLink>
                                    </li>

                                    <li className="footer-contacts">
                                        <Link
                                            to={{ pathname: Contacts.email.link }}
                                            target="_top"
                                            className="text-nowrap"
                                        >
                                            <img
                                                src={emailIcon}
                                                width="30"
                                                height="30"
                                                alt="email"
                                                style={{ marginTop: "1px" }}
                                            />

                                            <span>{Contacts.email.text}</span>
                                        </Link>
                                    </li>

                                    <li className="footer-contacts">
                                        <Link
                                            to={{ pathname: Contacts.phone.link }}
                                            target="_blank"
                                            className="text-nowrap"
                                        >
                                            <img
                                                src={phoneIcon}
                                                width="30"
                                                height="30"
                                                alt="phone"
                                                style={{ marginTop: "1px" }}
                                            />

                                            <span>{Contacts.phone.text}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <Footer3 />
                    </div>
                </div>
                <div className="social social-mobile text-center">
                    <Social />
                </div>
            </div>
        </div>
    );
}

export default Footer;
