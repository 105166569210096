import images from "../../assets/landingpages/bca/"

import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
const { jayhiltin, hotelDotNg, spleet, saire } = brandImages;


const { HeroImage, FastAccount, TeamBanking, LinkedAccount, MultipleBusinesses, RobustBusiness, Convenient, CustomNotification } = images;

const { money, cashBook, pos } = cardIcons;
export default {


    name: "Business Current Account",
    title: "Free Business Current Account",
    description: "Real bank accounts for fast growing businesses.\nThese account accept all forms of deposits.",
    link: "/business-current-account",
    image: HeroImage,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [
        {

            data: [
                {
                    title: "Fast Account Opening",
                    description: "It takes only 10mins to complete your account opening application. Your account gets approved and account number is issued within 24hrs.",
                    image: FastAccount
                },
                {
                    title: "Team Banking",
                    description: "Add unlimited team members with custom access levels to your business bank account. Set who approves, initiates, views and manages payments.",
                    image: TeamBanking
                },


                {
                    title: "Linked Accounts",
                    description: "Do you own accounts with other banks? Connect your other bank account to Lenco for free. Get robust business insights and analytics.",
                    image: LinkedAccount
                }







            ]
        },

        {
            dataType: "splitter",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {
                    title: "Multiple Businesses",
                    description: "Do you own multiple businesses? Manage all your businesses on Lenco. Simply switch between businesses to manage accounts.",
                    image: MultipleBusinesses
                },
                {
                    title: "Robust Business Accounts",
                    description: "Add comments to transactions, simple and easy to understand transaction history, filter and export account statements, attach invoices/receipts to transactions, auto-save recipients.",

                    image: RobustBusiness
                },
                {
                    title: "Convenient and Bureaucracy Free",
                    description: "No paperwork. Do everything on the web or App. No hidden fees. Absolute control. We put everything in your hands. Best customer support whenever you need.",
                    image: Convenient
                },
                {
                    title: "Custom Notifications",
                    description: "Customize the transaction notification received by your team. Grant full, truncated or no notifications. You can also give notification only access which is best for allowing employees at sales point receive inflow notification without your balances.",
                    image: CustomNotification
                }




            ]

        }


    ]

}