import Airtime from "./airtime.svg"
import AirtimeApi from "./airtimeApi.svg"
import HeroImage from "./HeroImage.svg"
import Utility from "./utility.svg"


export default {
    Airtime,
    AirtimeApi,
    HeroImage,
    Utility
}
