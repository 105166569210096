import React from "react";
import { Link } from "react-router-dom";
import "../../styles/sections/section8.scss";
import Accordion from "../Accordion";
import Contact from "../Contact";
import contactIconNumber from "../../assets/landingpages/Vector_1_slalwt.svg";
import contactIconWhatsapp from "../../assets/landingpages/Vector_2_xjwfx2.svg";
import contactIconEmail from "../../assets/landingpages/Vector_3_n0vfoc.svg"
import { Contacts } from "../../helper";

function Section8({
  Button1,
  Accordion1,
  Button2,
  Accordion2,
  Button3,
  Accordion3,
  Button4,
  Accordion4,
}) {

  return (
    <section className="container-fluid section-eight">
      <div className="container text-center">

        <div className="row section-eight-wrapper">
          <div className="col-lg-5">
            <h2 className="title mobile-header1">Need to Speak to us?</h2>
            <Link
              to={{ pathname: Contacts.phone.link }}
              target="_blank"
            >
              <Contact
                className="contact-info"
                contacticon={contactIconNumber}
                contactinfo={Contacts.phone.text}
              />
            </Link>
            <Link
              to={{ pathname: Contacts.whatsapp.link }}
              target="_blank"
            >
              <Contact
                className="contact-info"
                contacticon={contactIconWhatsapp}
                contactinfo={Contacts.whatsapp.text}
              />
            </Link>
            <Link
              to={{ pathname: Contacts.email.link }}
              target="_top"
            >
              <Contact
                className="contact-info"
                contacticon={contactIconEmail}
                contactinfo={Contacts.email.text}
              />
            </Link>
          </div>
          <div className="col-lg-7">
            <h2 className="title mobile-header1">Frequently asked questions</h2>

            <Accordion
              button1={Button1}
              button2={Button2}
              button3={Button3}
              button4={Button4}
              accordion1={Accordion1}
              accordion2={Accordion2}
              accordion3={Accordion3}
              accordion4={Accordion4}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section8;
