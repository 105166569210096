import Apicodes from "./apicodes.svg"
import UploadFile from "./uploadfile.svg"
import PayoutDashboard from "./payoutdashboard.svg"


export default {

    Apicodes,
    UploadFile,
    PayoutDashboard

}