import HeroImage from "../../assets/landingpages/startups/heroImage.svg"
import BannerImage from "../../assets/landingpages/startups/banner.svg"

import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
const { giftedMum, healthTracka, dojah, eziPay } = brandImages;


const { cardSecurity, moneyTransfer, restApi } = cardIcons;
export default {

    name: "Startup",
    title: "Business banking that works for Startups",
    description: "Initiate payments, automate reconciliation, perform thousands of transactions in seconds with our simple API.",
    link: "/startup",
    image: HeroImage,
    showAppStore: false,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }
        ,
        { title: "Explore Documentation", link: Urls.devUrl, className: "btn btn-hero-button btn-default mb-5 px-3 btn-ghost", externalLink: true, iconClassName: "fa fa-arrow-right" }

    ],
    sections: [
        {
            title: "Banking built for Startups",

            data: [

                {
                    title: "Virtual Account",
                    description: "Issue out virtual accounts to your Vendors, Merchants for seamless collection.",
                    image: cardSecurity
                },

                {
                    title: "Automated Disbursement",
                    description: "Programmatically disburse funds to your Clients, Vendors, and Merchants.",
                    image: moneyTransfer
                },
                {
                    title: "Bill Payment API",
                    description: "Purchase Airtime, Power, Data, Cable TV with Lenco API..",
                    image: restApi
                }







            ]
        },

        {
            title: "Lenco powers thousands of Startups like yours",
            dataType: "brand",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {

                    image: eziPay
                },
                {

                    image: giftedMum
                }, {

                    image: healthTracka
                }, {

                    image: dojah
                }


            ]

        }
        ,
        {
            dataType: "banner",

            extraProps: {
                image: BannerImage
            }

        }


    ]

}
