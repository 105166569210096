import {Switch, useLocation} from "react-router";
import "./App.css";
import "animate.css";
import Routes from "./routes/routes";
import TopBarProgress from "react-topbar-progress-indicator";
import {useEffect, useState} from "react";
import HelmetMeta from "./components/HelmetMeta";
import {HelmetProvider} from "react-helmet-async";

function App() {

    TopBarProgress.config({
        barColors: {
            0: "#5466f8",
            "1.0": "#5466f8",
        },
        shadowBlur: 1,
    });

    const [progress, setProgress] = useState(false);
    const [previousLocation, setPreviousLocation] = useState("");
    const location = useLocation();

    useEffect(() => {
        setPreviousLocation(location.pathname);
        setProgress(true);
        if (location.pathname === previousLocation) {
            setPreviousLocation("");
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setProgress(false);
    }, [previousLocation]);

    return (
        <HelmetProvider>
            <HelmetMeta country={"Nigeria"} />

            {progress && <TopBarProgress/>}
            <Switch>
                <Routes/>
            </Switch>
        </HelmetProvider>
    );
}

export default App;
