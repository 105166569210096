import images from "../../assets/landingpages/billpayment"

import cardIcons from "../../assets/icons"
import brandImages from "../../assets/brands";
import { Urls } from "../../helper";
const { jayhiltin, hotelDotNg, spleet, saire } = brandImages;


const { HeroImage, Airtime, AirtimeApi, Utility } = images;

const { money, cashBook, pos } = cardIcons;
export default {


    name: "Business Current Account",
    title: "Your Business Airtime & Utility Bills in one place. Built with your team in mind",
    // description: "Airtime | Data | Electricity",
    link: "/bill-payment",
    image: HeroImage,
    actions: [
        { title: "Open An Account", link: Urls.signup, externalLink: true }


    ],
    sections: [

        {
            dataType: "splitter",
            classNames:

                { title: "text-center" }
            ,

            data: [
                {
                    title: "Buy Airtime for Individuals and Teams",
                    description: "Purchase airtime in a breeze for an individual or in bulk for your team members with zero fees.",
                    image: Airtime
                },
                {
                    title: "Power your Business Utilities easily for free",
                    description: "Power your business utilities easily for free",

                    image: Utility
                },
                {
                    title: "Airtime and Payments via API",
                    description: "No paperwork. Do everything on the web or App. No hidden fees. Absolute control. We put everything in your hands. Best customer support whenever you need.",
                    image: AirtimeApi
                }




            ]

        }


    ]

}
